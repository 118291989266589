import type { PayloadAction } from "@reduxjs/toolkit"

import { createSlice } from "@reduxjs/toolkit"

import type { AppStoreType } from "./index"

import type { Post, Story } from "@src/types/generated-graphql/types"

import type { GroupStoriesType, IndexType } from "@src/types"

// type Stories = {
//   [key: number]: Story[]
// }

export interface IStory {
  openStoryModal: boolean
  currentPosts: Post[] | null
  stories: GroupStoriesType | null
  initIndexes: IndexType | null
  storiesInteractions: Story[]
  postInteractions: Post[]
}

const initialState: IStory = {
  openStoryModal: false,
  currentPosts: null,
  stories: null,
  initIndexes: null,
  storiesInteractions: [],
  postInteractions: [],
  
}

export const storySlice = createSlice({
  name: "STORY-SLICE",
  initialState,
  reducers: {
    setOpenStoryModal: (state, action: PayloadAction<boolean>) => {
      state.openStoryModal = action.payload
    },

    setCurrentPosts: (state, action: PayloadAction<Post[] | null>) => {
      state.currentPosts = action.payload
    },

    setStories: (state, action: PayloadAction<GroupStoriesType | null>) => {
      state.stories = action.payload
    },

    setInitIndexes: (state, action: PayloadAction<IndexType | null>) => {
      state.initIndexes = action.payload
    },

    addStoriesInteractions: (state, action: PayloadAction<Story>) => {
      state.storiesInteractions.push(action.payload)
    },

    resetStoriesInteractions: (state) => {
      state.storiesInteractions = []
    },

    addPostInteractions: (state, action: PayloadAction<Post>) => {
      state.postInteractions.push(action.payload)
    },

    resetPostInteractions: (state) => {
      state.postInteractions = []
    },
  },
})

export const {
  setOpenStoryModal,
  setCurrentPosts,
  setStories,
  setInitIndexes,
  addStoriesInteractions,
  resetStoriesInteractions,
  addPostInteractions,
  resetPostInteractions,
} = storySlice.actions

export const getOpenStoryModal = (state: AppStoreType) =>
  state.story.openStoryModal

export const getCurrentPosts = (state: AppStoreType) => state.story.currentPosts

export const getStories = (state: AppStoreType) => state.story.stories

export const getInitIndexes = (state: AppStoreType) => state.story.initIndexes

export const getStoriesInteractions = (state: AppStoreType) =>
  state.story.storiesInteractions

export default storySlice.reducer

export const getPostInteractions = (state: AppStoreType) =>
  state.story.postInteractions
