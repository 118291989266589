import { Fragment, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";

import { useQuery } from "@apollo/client";

import type { Area } from "@src/types/generated-graphql/types"

import StoriesModal from "@components/stories/modal";

// import { GET_AREAS } from "@src/graphql/queries";
import { GetTotalPostsAndAreasDocument } from "@src/types/generated-graphql/apollo-client";

import SidebarMenu from "../menu/sidebar";

import TopMenu from "../menu/top";

import Footer from "./footer";

import { useSession } from "next-auth/react";

import AudioPlayer from "@components/audio-player";

import { useRouter } from "next/router";

import { routesNoLayout } from "@src/utils/constanst"

type CProps = {
  children: React.ReactNode;
};

const Layout: React.FC<CProps> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const router = useRouter();

  const { data: session } = useSession();

  const { data: areaData } = useQuery<{ areas: Area[]; totalPosts: number }>(
    GetTotalPostsAndAreasDocument,
  );

  return (
    <div>
      {!routesNoLayout.includes(router.route) && session?.user ? (
        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-[#111111] pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>

                    <TopMenu setSidebarOpen={setSidebarOpen} />

                    <div className="mt-5 h-0 flex-1 overflow-y-auto py-4">
                      {areaData?.areas && (
                        <SidebarMenu
                          setSidebarOpen={setSidebarOpen}
                          areas={areaData.areas}
                          totalPosts={areaData.totalPosts}
                        />
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true" />
              </div>
            </Dialog>
          </Transition.Root>

          <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
            <div className="flex flex-grow flex-col overflow-y-auto overflow-x-hidden bg-[#111111] pt-5">
              <TopMenu setSidebarOpen={setSidebarOpen} />
              <div className="mt-5 flex flex-1 flex-col">
                {areaData?.areas && (
                  <SidebarMenu
                    setSidebarOpen={setSidebarOpen}
                    areas={areaData!.areas}
                    totalPosts={areaData.totalPosts}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-1 flex-col lg:pl-64">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow lg:hidden">
              <button
                type="button"
                className="p-3 text-white focus:outline-none lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon
                  className="h-10 w-10 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>

            <main className="relative w-full px-5 py-5">
              {children}
              <AudioPlayer />

              <StoriesModal />
            </main>
            <Footer />
          </div>
        </div>
      ) : (
        <main className="px-4 py-6">{children}</main>
      )}
    </div>
  );
};

export default Layout;
