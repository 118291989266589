import type { NormalizedCacheObject } from "@apollo/client"
import type { IncomingMessage } from "http"

import { ApolloClient, InMemoryCache, from } from "@apollo/client"
import { useMemo } from "react"

import {
  httpLinkError,
  initHttpLink,
  inMemoryCacheConfig,
} from "@src/config/apollo-client"

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

const createApolloClient = (req?: IncomingMessage) => {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: from([httpLinkError, initHttpLink(req)]),
    cache: new InMemoryCache(inMemoryCacheConfig),
    connectToDevTools: process.env.NODE_ENV !== "production",
  })
}

export const initializeApolloClient = (
  req?: IncomingMessage,
  initialState?: NormalizedCacheObject
) => {
  const _apolloClient = apolloClient ?? createApolloClient(req)

  // If your page has Next.js data fetching methods that use Apollo Client,
  // the initial state gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Restore the cache using the data passed from
    // getStaticProps/getServerSideProps combined with the existing cached data
    _apolloClient.cache.restore({ ...existingCache, ...initialState })
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient
  return _apolloClient
}

export const useApolloClient = (initialState: NormalizedCacheObject) =>
  useMemo(() => initializeApolloClient(undefined, initialState), [initialState])

export const extractApolloState = (
  apolloClient: ApolloClient<NormalizedCacheObject>
) => apolloClient.cache.extract()
