import Image from "next/image"

import LogoLilly from "../../public/Lilly_podcast_V4.svg"

const Footer = () => {
  return (
    <footer>
      <div className="w-full mt-auto footer-container relative flex flex-col items-center pt-10">
        <div className="w-11/12">
          <Image
            src={LogoLilly}
            alt=""
            className="w-24 h-auto mb-2 object-cover object-center  border-gray-200 "
          />
        </div>

        <div className="w-11/12">
          <h5 className="font-sans font-bold text-base text-white uppercase mb-2">
            Declaración de conflicto de intereses
          </h5>
          <p className="mb-2 leading-relaxed text-white text-xs">
            "Los profesionales del cuidado de la salud que aparecen en estos
            videos han sido contratados por Lilly o por Asociaciones Médicas en
            colaboración con Lilly para desarrollar específicamente el material
            y contenido de los mismos. Los expositores pueden ser o estar
            contratados por instituciones de salud u otras empresas
            farmacéuticas para llevar a cabo diversos servicios profesionales."
          </p>

          <p className="mb-2 leading-relaxed text-white text-xs">
            A menos que se mencione expresamente lo contrario, todos los
            productos, servicios e información que aparecen en este portal son
            propiedad de Eli Lilly and Company y sus afiliadas, no se podrá
            hacer uso de lo aquí establecido sin autorización previa conducente
            de parte de Eli Lilly and Company.
          </p>

          <p className="mb-2 leading-relaxed text-white text-xs">
            Todos los Derechos Reservados. El presente sitio web no fue diseñado
            o intenta por ningún medio atraer individuos menores de 18 años.
          </p>
        </div>

        <div className="w-11/12">
          <p className="mb-2 leading-relaxed text-white text-xs">
            Material exclusivo para Profesionales de la Salud.
          </p>
          <p className="mb-2 leading-relaxed text-white text-xs">
            <a
              href="https://www.lillyprivacy.com/LACAM-es/hcp"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Aviso de Privacidad
            </a>{" "}
            |{" "}
            <a
              href="https://www.lillylatam.com/t%C3%A9rminos-de-uso"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Términos de uso
            </a>{" "}
            |{" "}
            <a
              href="https://www.lillylatam.com/declaracion-de-accesibilidad"
              target="_blank"
              className="underline"
              rel="noreferrer"
            >
              Declaración de Accesibilidad
            </a>
          </p>
          <p className="mb-2 leading-relaxed text-white text-xs">
            Consulte información para prescribir. Todos los derechos reservados
            Eli Lilly & Co 2023. PP-MG-AR-0125 | 05/2024 - SAA 213300202C4422
          </p>
          {/* <p className="mb-2 leading-relaxed text-white text-xs">Todos los derechos reservados Eli Lilly & Co 2023</p> */}
          {/* <p className="mb-2 leading-relaxed text-white text-xs">PP-MG-AR-0125 | 05/2024 - SAA 213300202C4422</p> */}
        </div>

        <div className="text-center lg:w-2/3 w-full">
          <div className="px-4 md:px-12 xl:px-28 pt-10">
            <div className="h-10 bg-gradient-to-r from-dark-lilly to-red-lilly rounded-t-full" />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
