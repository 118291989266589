import { setMilliseconds, format, parseISO } from "date-fns";

const getTimezoneOffset = (timeZone: string, date: Date) => {
  const tz = date
    .toLocaleString("en", { timeZone, timeStyle: "long" })
    .split(" ")
    .slice(-1)[0];
  const dateString = date.toString();
  return Date.parse(`${dateString} UTC`) - Date.parse(`${dateString} ${tz}`);
};

export const getTimezoneDate = (timeZone: string) => {
  const date = new Date();
  const offset = getTimezoneOffset(timeZone, date);
  return setMilliseconds(date, offset);
};

export const getFormattedVideoDuration = (totalSeconds: number) => {
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
};

export const convertSecondsToTime = (seconds: number) => {
  const tiempoInMilliseconds = seconds * 1000;
  const date = parseISO("1970-01-01T" + new Date(tiempoInMilliseconds).toISOString().substr(11, 8));
  return format(date, "HH:mm:ss");
};
