import type * as Types from './operations-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export const BasicDataEpisodeFragmentDoc = gql`
    fragment BasicDataEpisode on Episode {
  id
  name
  duration
  externalId
  share
  podcastThumbnail
  playbackId
  podcast {
    slug
    thumbnailUrl
    name
    color {
      primary
      gradient
    }
  }
}
    `;
export const FragmentEpisodeFragmentDoc = gql`
    fragment FragmentEpisode on Episode {
  ...BasicDataEpisode
  speakers {
    name
  }
  pmats {
    code
  }
  isBookmarked
  areas {
    name
  }
}
    ${BasicDataEpisodeFragmentDoc}`;
export const FragmentPostFragmentDoc = gql`
    fragment FragmentPost on Post {
  id
  title
  primaryImage
  secondaryImage
  createdAt
  hasSeen
  area {
    name
    iconUrl
    color {
      primary
      gradient
    }
  }
  stories {
    id
    playbackId
    createdAt
    duration
    storyLink {
      id
      url
      episode {
        id
        name
        share
        podcast {
          slug
        }
      }
    }
  }
}
    `;
export const BasicDataViewFragmentDoc = gql`
    fragment BasicDataView on View {
  isLillyUser
  status
  visitUserCode
  visitUserName
  visitUserLastName
  visitUserSecondLastName
  visitUserLicense
  visitEmail
  visitUserCountryName
  sessionDuration
  visitOs
  visitDevice
  visitBrowser
}
    `;
export const GetAreasDocument = gql`
    query getAreas {
  areas: getAreas {
    name
    thumbnailUrl
    slug
  }
}
    `;

/**
 * __useGetAreasQuery__
 *
 * To run a query within a React component, call `useGetAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAreasQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAreasQuery, Types.GetAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAreasQuery, Types.GetAreasQueryVariables>(GetAreasDocument, options);
      }
export function useGetAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAreasQuery, Types.GetAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAreasQuery, Types.GetAreasQueryVariables>(GetAreasDocument, options);
        }
export type GetAreasQueryHookResult = ReturnType<typeof useGetAreasQuery>;
export type GetAreasLazyQueryHookResult = ReturnType<typeof useGetAreasLazyQuery>;
export type GetAreasQueryResult = Apollo.QueryResult<Types.GetAreasQuery, Types.GetAreasQueryVariables>;
export const GetAreaWithEpisodesDocument = gql`
    query GetAreaWithEpisodes($slug: String!) {
  area: getArea(slug: $slug) {
    id
    name
    thumbnailUrl
    iconUrl
    color {
      primary
      gradient
    }
  }
  episodes: getEpisodesByArea(slug: $slug) {
    ...FragmentEpisode
  }
  posts: getPostsByAreaSlug(slug: $slug) {
    ...FragmentPost
  }
}
    ${FragmentEpisodeFragmentDoc}
${FragmentPostFragmentDoc}`;

/**
 * __useGetAreaWithEpisodesQuery__
 *
 * To run a query within a React component, call `useGetAreaWithEpisodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAreaWithEpisodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAreaWithEpisodesQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetAreaWithEpisodesQuery(baseOptions: Apollo.QueryHookOptions<Types.GetAreaWithEpisodesQuery, Types.GetAreaWithEpisodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAreaWithEpisodesQuery, Types.GetAreaWithEpisodesQueryVariables>(GetAreaWithEpisodesDocument, options);
      }
export function useGetAreaWithEpisodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAreaWithEpisodesQuery, Types.GetAreaWithEpisodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAreaWithEpisodesQuery, Types.GetAreaWithEpisodesQueryVariables>(GetAreaWithEpisodesDocument, options);
        }
export type GetAreaWithEpisodesQueryHookResult = ReturnType<typeof useGetAreaWithEpisodesQuery>;
export type GetAreaWithEpisodesLazyQueryHookResult = ReturnType<typeof useGetAreaWithEpisodesLazyQuery>;
export type GetAreaWithEpisodesQueryResult = Apollo.QueryResult<Types.GetAreaWithEpisodesQuery, Types.GetAreaWithEpisodesQueryVariables>;
export const GetEpisodeByIdDocument = gql`
    query getEpisodeById($id: BigInt!) {
  getEpisodeById(id: $id) {
    name
  }
}
    `;

/**
 * __useGetEpisodeByIdQuery__
 *
 * To run a query within a React component, call `useGetEpisodeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpisodeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpisodeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEpisodeByIdQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEpisodeByIdQuery, Types.GetEpisodeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEpisodeByIdQuery, Types.GetEpisodeByIdQueryVariables>(GetEpisodeByIdDocument, options);
      }
export function useGetEpisodeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEpisodeByIdQuery, Types.GetEpisodeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEpisodeByIdQuery, Types.GetEpisodeByIdQueryVariables>(GetEpisodeByIdDocument, options);
        }
export type GetEpisodeByIdQueryHookResult = ReturnType<typeof useGetEpisodeByIdQuery>;
export type GetEpisodeByIdLazyQueryHookResult = ReturnType<typeof useGetEpisodeByIdLazyQuery>;
export type GetEpisodeByIdQueryResult = Apollo.QueryResult<Types.GetEpisodeByIdQuery, Types.GetEpisodeByIdQueryVariables>;
export const GetEpisodeDocument = gql`
    query getEpisode($share: String!, $podcastSlug: String!) {
  episode: getEpisode(share: $share, podcastSlug: $podcastSlug) {
    id
    name
    abstract
    duration
    externalId
    playbackId
    share
    thumbnailCartonUrl
    tags
    code
    pdfUrl
    podcast {
      name
      thumbnailUrl
      slug
      iconUrl
      color {
        primary
        gradient
      }
    }
    veevaVault {
      code
      fdc
    }
    speakers {
      name
      cv
      imageUrl
    }
    pmats {
      code
    }
  }
  posts: getPostsByEpisodeShare(share: $share, podcastSlug: $podcastSlug) {
    ...FragmentPost
  }
}
    ${FragmentPostFragmentDoc}`;

/**
 * __useGetEpisodeQuery__
 *
 * To run a query within a React component, call `useGetEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpisodeQuery({
 *   variables: {
 *      share: // value for 'share'
 *      podcastSlug: // value for 'podcastSlug'
 *   },
 * });
 */
export function useGetEpisodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEpisodeQuery, Types.GetEpisodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEpisodeQuery, Types.GetEpisodeQueryVariables>(GetEpisodeDocument, options);
      }
export function useGetEpisodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEpisodeQuery, Types.GetEpisodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEpisodeQuery, Types.GetEpisodeQueryVariables>(GetEpisodeDocument, options);
        }
export type GetEpisodeQueryHookResult = ReturnType<typeof useGetEpisodeQuery>;
export type GetEpisodeLazyQueryHookResult = ReturnType<typeof useGetEpisodeLazyQuery>;
export type GetEpisodeQueryResult = Apollo.QueryResult<Types.GetEpisodeQuery, Types.GetEpisodeQueryVariables>;
export const Get_Podcast_And_EpisodesDocument = gql`
    query GET_PODCAST_AND_EPISODES($podcast_slug: String!) {
  podcast: getPodcast(podcast_slug: $podcast_slug) {
    id
    name
    thumbnailUrl
    iconUrl
    color {
      primary
      gradient
    }
  }
  podcastEpisodes: getAllPodcastEpisodes(podcast_slug: $podcast_slug) {
    ...FragmentEpisode
  }
  podcasts: getPodcasts {
    id
    name
    slug
    color {
      primary
      gradient
    }
  }
}
    ${FragmentEpisodeFragmentDoc}`;

/**
 * __useGet_Podcast_And_EpisodesQuery__
 *
 * To run a query within a React component, call `useGet_Podcast_And_EpisodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Podcast_And_EpisodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Podcast_And_EpisodesQuery({
 *   variables: {
 *      podcast_slug: // value for 'podcast_slug'
 *   },
 * });
 */
export function useGet_Podcast_And_EpisodesQuery(baseOptions: Apollo.QueryHookOptions<Types.Get_Podcast_And_EpisodesQuery, Types.Get_Podcast_And_EpisodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.Get_Podcast_And_EpisodesQuery, Types.Get_Podcast_And_EpisodesQueryVariables>(Get_Podcast_And_EpisodesDocument, options);
      }
export function useGet_Podcast_And_EpisodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.Get_Podcast_And_EpisodesQuery, Types.Get_Podcast_And_EpisodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.Get_Podcast_And_EpisodesQuery, Types.Get_Podcast_And_EpisodesQueryVariables>(Get_Podcast_And_EpisodesDocument, options);
        }
export type Get_Podcast_And_EpisodesQueryHookResult = ReturnType<typeof useGet_Podcast_And_EpisodesQuery>;
export type Get_Podcast_And_EpisodesLazyQueryHookResult = ReturnType<typeof useGet_Podcast_And_EpisodesLazyQuery>;
export type Get_Podcast_And_EpisodesQueryResult = Apollo.QueryResult<Types.Get_Podcast_And_EpisodesQuery, Types.Get_Podcast_And_EpisodesQueryVariables>;
export const GetUserBookmarksDocument = gql`
    query getUserBookmarks {
  bookmarks: getUserBookmarks {
    ...FragmentEpisode
  }
}
    ${FragmentEpisodeFragmentDoc}`;

/**
 * __useGetUserBookmarksQuery__
 *
 * To run a query within a React component, call `useGetUserBookmarksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserBookmarksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserBookmarksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserBookmarksQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetUserBookmarksQuery, Types.GetUserBookmarksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserBookmarksQuery, Types.GetUserBookmarksQueryVariables>(GetUserBookmarksDocument, options);
      }
export function useGetUserBookmarksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserBookmarksQuery, Types.GetUserBookmarksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserBookmarksQuery, Types.GetUserBookmarksQueryVariables>(GetUserBookmarksDocument, options);
        }
export type GetUserBookmarksQueryHookResult = ReturnType<typeof useGetUserBookmarksQuery>;
export type GetUserBookmarksLazyQueryHookResult = ReturnType<typeof useGetUserBookmarksLazyQuery>;
export type GetUserBookmarksQueryResult = Apollo.QueryResult<Types.GetUserBookmarksQuery, Types.GetUserBookmarksQueryVariables>;
export const ToggleBookmarkDocument = gql`
    mutation toggleBookmark($episodeId: ID!) {
  toggleBookmark(episodeId: $episodeId)
}
    `;
export type ToggleBookmarkMutationFn = Apollo.MutationFunction<Types.ToggleBookmarkMutation, Types.ToggleBookmarkMutationVariables>;

/**
 * __useToggleBookmarkMutation__
 *
 * To run a mutation, you first call `useToggleBookmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBookmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBookmarkMutation, { data, loading, error }] = useToggleBookmarkMutation({
 *   variables: {
 *      episodeId: // value for 'episodeId'
 *   },
 * });
 */
export function useToggleBookmarkMutation(baseOptions?: Apollo.MutationHookOptions<Types.ToggleBookmarkMutation, Types.ToggleBookmarkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.ToggleBookmarkMutation, Types.ToggleBookmarkMutationVariables>(ToggleBookmarkDocument, options);
      }
export type ToggleBookmarkMutationHookResult = ReturnType<typeof useToggleBookmarkMutation>;
export type ToggleBookmarkMutationResult = Apollo.MutationResult<Types.ToggleBookmarkMutation>;
export type ToggleBookmarkMutationOptions = Apollo.BaseMutationOptions<Types.ToggleBookmarkMutation, Types.ToggleBookmarkMutationVariables>;
export const GetPrevNextEpisodeDocument = gql`
    query getPrevNextEpisode($episodeId: ID!) {
  prevEpisode: getPrevEpisode(episodeId: $episodeId) {
    ...BasicDataEpisode
  }
  nextEpisode: getNextEpisode(episodeId: $episodeId) {
    ...BasicDataEpisode
  }
}
    ${BasicDataEpisodeFragmentDoc}`;

/**
 * __useGetPrevNextEpisodeQuery__
 *
 * To run a query within a React component, call `useGetPrevNextEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrevNextEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrevNextEpisodeQuery({
 *   variables: {
 *      episodeId: // value for 'episodeId'
 *   },
 * });
 */
export function useGetPrevNextEpisodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPrevNextEpisodeQuery, Types.GetPrevNextEpisodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPrevNextEpisodeQuery, Types.GetPrevNextEpisodeQueryVariables>(GetPrevNextEpisodeDocument, options);
      }
export function useGetPrevNextEpisodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPrevNextEpisodeQuery, Types.GetPrevNextEpisodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPrevNextEpisodeQuery, Types.GetPrevNextEpisodeQueryVariables>(GetPrevNextEpisodeDocument, options);
        }
export type GetPrevNextEpisodeQueryHookResult = ReturnType<typeof useGetPrevNextEpisodeQuery>;
export type GetPrevNextEpisodeLazyQueryHookResult = ReturnType<typeof useGetPrevNextEpisodeLazyQuery>;
export type GetPrevNextEpisodeQueryResult = Apollo.QueryResult<Types.GetPrevNextEpisodeQuery, Types.GetPrevNextEpisodeQueryVariables>;
export const GetAllEpisodesDocument = gql`
    query getAllEpisodes {
  allEpisodes: getAllEpisodes {
    ...FragmentEpisode
  }
}
    ${FragmentEpisodeFragmentDoc}`;

/**
 * __useGetAllEpisodesQuery__
 *
 * To run a query within a React component, call `useGetAllEpisodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEpisodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEpisodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEpisodesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllEpisodesQuery, Types.GetAllEpisodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllEpisodesQuery, Types.GetAllEpisodesQueryVariables>(GetAllEpisodesDocument, options);
      }
export function useGetAllEpisodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllEpisodesQuery, Types.GetAllEpisodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllEpisodesQuery, Types.GetAllEpisodesQueryVariables>(GetAllEpisodesDocument, options);
        }
export type GetAllEpisodesQueryHookResult = ReturnType<typeof useGetAllEpisodesQuery>;
export type GetAllEpisodesLazyQueryHookResult = ReturnType<typeof useGetAllEpisodesLazyQuery>;
export type GetAllEpisodesQueryResult = Apollo.QueryResult<Types.GetAllEpisodesQuery, Types.GetAllEpisodesQueryVariables>;
export const GetEpisodesByTagDocument = gql`
    query getEpisodesByTag($tag: String!) {
  episodes: getEpisodesByTag(tag: $tag) {
    ...BasicDataEpisode
    createdAt
    speakers {
      name
    }
  }
}
    ${BasicDataEpisodeFragmentDoc}`;

/**
 * __useGetEpisodesByTagQuery__
 *
 * To run a query within a React component, call `useGetEpisodesByTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpisodesByTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpisodesByTagQuery({
 *   variables: {
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGetEpisodesByTagQuery(baseOptions: Apollo.QueryHookOptions<Types.GetEpisodesByTagQuery, Types.GetEpisodesByTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetEpisodesByTagQuery, Types.GetEpisodesByTagQueryVariables>(GetEpisodesByTagDocument, options);
      }
export function useGetEpisodesByTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetEpisodesByTagQuery, Types.GetEpisodesByTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetEpisodesByTagQuery, Types.GetEpisodesByTagQueryVariables>(GetEpisodesByTagDocument, options);
        }
export type GetEpisodesByTagQueryHookResult = ReturnType<typeof useGetEpisodesByTagQuery>;
export type GetEpisodesByTagLazyQueryHookResult = ReturnType<typeof useGetEpisodesByTagLazyQuery>;
export type GetEpisodesByTagQueryResult = Apollo.QueryResult<Types.GetEpisodesByTagQuery, Types.GetEpisodesByTagQueryVariables>;
export const GetRecap2023EpisodesDocument = gql`
    query GetRecap2023Episodes {
  episodes: getRecap2023Episodes {
    ...FragmentEpisode
  }
}
    ${FragmentEpisodeFragmentDoc}`;

/**
 * __useGetRecap2023EpisodesQuery__
 *
 * To run a query within a React component, call `useGetRecap2023EpisodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecap2023EpisodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecap2023EpisodesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecap2023EpisodesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetRecap2023EpisodesQuery, Types.GetRecap2023EpisodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRecap2023EpisodesQuery, Types.GetRecap2023EpisodesQueryVariables>(GetRecap2023EpisodesDocument, options);
      }
export function useGetRecap2023EpisodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRecap2023EpisodesQuery, Types.GetRecap2023EpisodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRecap2023EpisodesQuery, Types.GetRecap2023EpisodesQueryVariables>(GetRecap2023EpisodesDocument, options);
        }
export type GetRecap2023EpisodesQueryHookResult = ReturnType<typeof useGetRecap2023EpisodesQuery>;
export type GetRecap2023EpisodesLazyQueryHookResult = ReturnType<typeof useGetRecap2023EpisodesLazyQuery>;
export type GetRecap2023EpisodesQueryResult = Apollo.QueryResult<Types.GetRecap2023EpisodesQuery, Types.GetRecap2023EpisodesQueryVariables>;
export const GetPodcastsAreasSliderDocument = gql`
    query GetPodcastsAreasSlider {
  podcasts: getPodcasts {
    name
    thumbnailUrl
    slug
  }
  areas: getAreas {
    id
    name
    thumbnailUrl
    slug
  }
  mainSlider: getMainSlider {
    share
    name
    thumbnailBannerUrl
    podcast {
      name
      slug
      color {
        primary
        gradient
      }
    }
  }
  watchedEpisodes: getContinueWatching {
    id
    name
    share
    podcast {
      name
      slug
      color {
        primary
      }
    }
    areas {
      name
      iconUrl
      thumbnailUrl
    }
  }
  randomEpisodes: getRandomEpisodes {
    id
    name
    share
    podcast {
      name
      slug
      color {
        primary
      }
    }
    areas {
      name
      iconUrl
      thumbnailUrl
    }
  }
  posts: getPosts {
    ...FragmentPost
  }
}
    ${FragmentPostFragmentDoc}`;

/**
 * __useGetPodcastsAreasSliderQuery__
 *
 * To run a query within a React component, call `useGetPodcastsAreasSliderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastsAreasSliderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastsAreasSliderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPodcastsAreasSliderQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPodcastsAreasSliderQuery, Types.GetPodcastsAreasSliderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPodcastsAreasSliderQuery, Types.GetPodcastsAreasSliderQueryVariables>(GetPodcastsAreasSliderDocument, options);
      }
export function useGetPodcastsAreasSliderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPodcastsAreasSliderQuery, Types.GetPodcastsAreasSliderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPodcastsAreasSliderQuery, Types.GetPodcastsAreasSliderQueryVariables>(GetPodcastsAreasSliderDocument, options);
        }
export type GetPodcastsAreasSliderQueryHookResult = ReturnType<typeof useGetPodcastsAreasSliderQuery>;
export type GetPodcastsAreasSliderLazyQueryHookResult = ReturnType<typeof useGetPodcastsAreasSliderLazyQuery>;
export type GetPodcastsAreasSliderQueryResult = Apollo.QueryResult<Types.GetPodcastsAreasSliderQuery, Types.GetPodcastsAreasSliderQueryVariables>;
export const GetPodcastDocument = gql`
    query GetPodcast($podcast_slug: String!) {
  podcast: getPodcast(podcast_slug: $podcast_slug) {
    id
    name
    thumbnailUrl
    color {
      id
      primary
      gradient
    }
  }
}
    `;

/**
 * __useGetPodcastQuery__
 *
 * To run a query within a React component, call `useGetPodcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastQuery({
 *   variables: {
 *      podcast_slug: // value for 'podcast_slug'
 *   },
 * });
 */
export function useGetPodcastQuery(baseOptions: Apollo.QueryHookOptions<Types.GetPodcastQuery, Types.GetPodcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPodcastQuery, Types.GetPodcastQueryVariables>(GetPodcastDocument, options);
      }
export function useGetPodcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPodcastQuery, Types.GetPodcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPodcastQuery, Types.GetPodcastQueryVariables>(GetPodcastDocument, options);
        }
export type GetPodcastQueryHookResult = ReturnType<typeof useGetPodcastQuery>;
export type GetPodcastLazyQueryHookResult = ReturnType<typeof useGetPodcastLazyQuery>;
export type GetPodcastQueryResult = Apollo.QueryResult<Types.GetPodcastQuery, Types.GetPodcastQueryVariables>;
export const GetPodcastsDocument = gql`
    query GetPodcasts {
  podcasts: getPodcasts {
    id
    name
    thumbnailUrl
    slug
  }
}
    `;

/**
 * __useGetPodcastsQuery__
 *
 * To run a query within a React component, call `useGetPodcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPodcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPodcastsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPodcastsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPodcastsQuery, Types.GetPodcastsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPodcastsQuery, Types.GetPodcastsQueryVariables>(GetPodcastsDocument, options);
      }
export function useGetPodcastsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPodcastsQuery, Types.GetPodcastsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPodcastsQuery, Types.GetPodcastsQueryVariables>(GetPodcastsDocument, options);
        }
export type GetPodcastsQueryHookResult = ReturnType<typeof useGetPodcastsQuery>;
export type GetPodcastsLazyQueryHookResult = ReturnType<typeof useGetPodcastsLazyQuery>;
export type GetPodcastsQueryResult = Apollo.QueryResult<Types.GetPodcastsQuery, Types.GetPodcastsQueryVariables>;
export const GetPostsDocument = gql`
    query GetPosts {
  posts: getPosts {
    ...FragmentPost
  }
}
    ${FragmentPostFragmentDoc}`;

/**
 * __useGetPostsQuery__
 *
 * To run a query within a React component, call `useGetPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostsQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetPostsQuery, Types.GetPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetPostsQuery, Types.GetPostsQueryVariables>(GetPostsDocument, options);
      }
export function useGetPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetPostsQuery, Types.GetPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetPostsQuery, Types.GetPostsQueryVariables>(GetPostsDocument, options);
        }
export type GetPostsQueryHookResult = ReturnType<typeof useGetPostsQuery>;
export type GetPostsLazyQueryHookResult = ReturnType<typeof useGetPostsLazyQuery>;
export type GetPostsQueryResult = Apollo.QueryResult<Types.GetPostsQuery, Types.GetPostsQueryVariables>;
export const GetTotalPostsAndAreasDocument = gql`
    query getTotalPostsAndAreas {
  totalPosts: getTotalPosts
  areas: getAreas {
    id
    name
    thumbnailUrl
    slug
  }
}
    `;

/**
 * __useGetTotalPostsAndAreasQuery__
 *
 * To run a query within a React component, call `useGetTotalPostsAndAreasQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTotalPostsAndAreasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTotalPostsAndAreasQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTotalPostsAndAreasQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetTotalPostsAndAreasQuery, Types.GetTotalPostsAndAreasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetTotalPostsAndAreasQuery, Types.GetTotalPostsAndAreasQueryVariables>(GetTotalPostsAndAreasDocument, options);
      }
export function useGetTotalPostsAndAreasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetTotalPostsAndAreasQuery, Types.GetTotalPostsAndAreasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetTotalPostsAndAreasQuery, Types.GetTotalPostsAndAreasQueryVariables>(GetTotalPostsAndAreasDocument, options);
        }
export type GetTotalPostsAndAreasQueryHookResult = ReturnType<typeof useGetTotalPostsAndAreasQuery>;
export type GetTotalPostsAndAreasLazyQueryHookResult = ReturnType<typeof useGetTotalPostsAndAreasLazyQuery>;
export type GetTotalPostsAndAreasQueryResult = Apollo.QueryResult<Types.GetTotalPostsAndAreasQuery, Types.GetTotalPostsAndAreasQueryVariables>;
export const GetAllEpisodesOnDemandDocument = gql`
    query GetAllEpisodesOnDemand {
  episodes: getAllEpisodesOnDemand {
    id
    name
  }
}
    `;

/**
 * __useGetAllEpisodesOnDemandQuery__
 *
 * To run a query within a React component, call `useGetAllEpisodesOnDemandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEpisodesOnDemandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEpisodesOnDemandQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllEpisodesOnDemandQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetAllEpisodesOnDemandQuery, Types.GetAllEpisodesOnDemandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetAllEpisodesOnDemandQuery, Types.GetAllEpisodesOnDemandQueryVariables>(GetAllEpisodesOnDemandDocument, options);
      }
export function useGetAllEpisodesOnDemandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetAllEpisodesOnDemandQuery, Types.GetAllEpisodesOnDemandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetAllEpisodesOnDemandQuery, Types.GetAllEpisodesOnDemandQueryVariables>(GetAllEpisodesOnDemandDocument, options);
        }
export type GetAllEpisodesOnDemandQueryHookResult = ReturnType<typeof useGetAllEpisodesOnDemandQuery>;
export type GetAllEpisodesOnDemandLazyQueryHookResult = ReturnType<typeof useGetAllEpisodesOnDemandLazyQuery>;
export type GetAllEpisodesOnDemandQueryResult = Apollo.QueryResult<Types.GetAllEpisodesOnDemandQuery, Types.GetAllEpisodesOnDemandQueryVariables>;
export const GetViewsByEpisodeDocument = gql`
    query GetViewsByEpisode($id: BigInt!, $page: Int!, $noPaginated: Boolean, $startDate: String, $endDate: String, $take: String, $skip: String) {
  data: getViewsByEpisode(
    id: $id
    page: $page
    noPaginated: $noPaginated
    startDate: $startDate
    endDate: $endDate
    take: $take
    skip: $skip
  ) {
    ...BasicDataView
    startAt
    minuteStartAt
    minuteEndAt
    channel
  }
  totalData: getTotalViewsByEpisode(
    id: $id
    startDate: $startDate
    endDate: $endDate
  ) {
    pages
    total
    videoId
    duration
    uniqueTotal
  }
}
    ${BasicDataViewFragmentDoc}`;

/**
 * __useGetViewsByEpisodeQuery__
 *
 * To run a query within a React component, call `useGetViewsByEpisodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewsByEpisodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewsByEpisodeQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      noPaginated: // value for 'noPaginated'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetViewsByEpisodeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetViewsByEpisodeQuery, Types.GetViewsByEpisodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetViewsByEpisodeQuery, Types.GetViewsByEpisodeQueryVariables>(GetViewsByEpisodeDocument, options);
      }
export function useGetViewsByEpisodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetViewsByEpisodeQuery, Types.GetViewsByEpisodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetViewsByEpisodeQuery, Types.GetViewsByEpisodeQueryVariables>(GetViewsByEpisodeDocument, options);
        }
export type GetViewsByEpisodeQueryHookResult = ReturnType<typeof useGetViewsByEpisodeQuery>;
export type GetViewsByEpisodeLazyQueryHookResult = ReturnType<typeof useGetViewsByEpisodeLazyQuery>;
export type GetViewsByEpisodeQueryResult = Apollo.QueryResult<Types.GetViewsByEpisodeQuery, Types.GetViewsByEpisodeQueryVariables>;
export const GetViewsByPodcastDocument = gql`
    query getViewsByPodcast($id: BigInt!, $page: Int!, $noPaginated: Boolean, $startDate: String, $endDate: String, $take: String, $skip: String) {
  data: getViewsByPodcast(
    id: $id
    page: $page
    noPaginated: $noPaginated
    startDate: $startDate
    endDate: $endDate
    take: $take
    skip: $skip
  ) {
    isLillyUser
    visitUserCode
    visitUserName
    visitUserLastName
    visitUserSecondLastName
    visitUserLicense
    visitEmail
    visitUserCountryName
    minuteStartAt
    minuteEndAt
    sessionDuration
    eventDuration
    videoId
    videoSeen
    speakers
    veevaVaultsCodes
    brands
    veevaVaultsIndications
    visitDevice
    visitOs
    visitBrowser
    startAt
    channel
  }
  totalData: getTotalViewsByPodcast(
    id: $id
    startDate: $startDate
    endDate: $endDate
  ) {
    pages
    total
  }
}
    `;

/**
 * __useGetViewsByPodcastQuery__
 *
 * To run a query within a React component, call `useGetViewsByPodcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewsByPodcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewsByPodcastQuery({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *      noPaginated: // value for 'noPaginated'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetViewsByPodcastQuery(baseOptions: Apollo.QueryHookOptions<Types.GetViewsByPodcastQuery, Types.GetViewsByPodcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetViewsByPodcastQuery, Types.GetViewsByPodcastQueryVariables>(GetViewsByPodcastDocument, options);
      }
export function useGetViewsByPodcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetViewsByPodcastQuery, Types.GetViewsByPodcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetViewsByPodcastQuery, Types.GetViewsByPodcastQueryVariables>(GetViewsByPodcastDocument, options);
        }
export type GetViewsByPodcastQueryHookResult = ReturnType<typeof useGetViewsByPodcastQuery>;
export type GetViewsByPodcastLazyQueryHookResult = ReturnType<typeof useGetViewsByPodcastLazyQuery>;
export type GetViewsByPodcastQueryResult = Apollo.QueryResult<Types.GetViewsByPodcastQuery, Types.GetViewsByPodcastQueryVariables>;
export const GetViewsDocument = gql`
    query GetViews($page: Int!, $noPaginated: Boolean, $startDate: String, $endDate: String) {
  data: getViews(
    page: $page
    noPaginated: $noPaginated
    startDate: $startDate
    endDate: $endDate
  ) {
    ...BasicDataView
    minuteStartAt
    minuteEndAt
    speakers
    brands
    veevaVaultsCodes
    veevaVaultsIndications
    episodeDuration
    episodePodcastName
    visitStartAt
    visitOriginName
    visitUserSpecialtyName
    episodeShare
    episodeName
    seen
    last
    accumulated
    episodeCodeCampaign
    channel
  }
  totalData: getTotalViews(startDate: $startDate, endDate: $endDate) {
    pages
    total
    lillyUsers
    noLillyUsers
  }
}
    ${BasicDataViewFragmentDoc}`;

/**
 * __useGetViewsQuery__
 *
 * To run a query within a React component, call `useGetViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetViewsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      noPaginated: // value for 'noPaginated'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetViewsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetViewsQuery, Types.GetViewsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetViewsQuery, Types.GetViewsQueryVariables>(GetViewsDocument, options);
      }
export function useGetViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetViewsQuery, Types.GetViewsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetViewsQuery, Types.GetViewsQueryVariables>(GetViewsDocument, options);
        }
export type GetViewsQueryHookResult = ReturnType<typeof useGetViewsQuery>;
export type GetViewsLazyQueryHookResult = ReturnType<typeof useGetViewsLazyQuery>;
export type GetViewsQueryResult = Apollo.QueryResult<Types.GetViewsQuery, Types.GetViewsQueryVariables>;
export const GetRegisteredUsersDocument = gql`
    query GetRegisteredUsers($page: Int!, $noPaginated: Boolean, $startDate: String, $endDate: String, $take: String, $skip: String) {
  data: getRegisteredUsers(
    page: $page
    noPaginated: $noPaginated
    startDate: $startDate
    endDate: $endDate
    take: $take
    skip: $skip
  ) {
    code
    name
    lastName
    secondLastName
    license
    email
    countryName
    specialtyName
    createdAt
  }
  totalData: getTotalRegisteredUsers(startDate: $startDate, endDate: $endDate) {
    pages
    total
  }
}
    `;

/**
 * __useGetRegisteredUsersQuery__
 *
 * To run a query within a React component, call `useGetRegisteredUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      noPaginated: // value for 'noPaginated'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetRegisteredUsersQuery(baseOptions: Apollo.QueryHookOptions<Types.GetRegisteredUsersQuery, Types.GetRegisteredUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetRegisteredUsersQuery, Types.GetRegisteredUsersQueryVariables>(GetRegisteredUsersDocument, options);
      }
export function useGetRegisteredUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetRegisteredUsersQuery, Types.GetRegisteredUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetRegisteredUsersQuery, Types.GetRegisteredUsersQueryVariables>(GetRegisteredUsersDocument, options);
        }
export type GetRegisteredUsersQueryHookResult = ReturnType<typeof useGetRegisteredUsersQuery>;
export type GetRegisteredUsersLazyQueryHookResult = ReturnType<typeof useGetRegisteredUsersLazyQuery>;
export type GetRegisteredUsersQueryResult = Apollo.QueryResult<Types.GetRegisteredUsersQuery, Types.GetRegisteredUsersQueryVariables>;
export const GetSpeakerDocument = gql`
    query getSpeaker($id: BigInt!) {
  getSpeaker(id: $id) {
    name
  }
}
    `;

/**
 * __useGetSpeakerQuery__
 *
 * To run a query within a React component, call `useGetSpeakerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpeakerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpeakerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSpeakerQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSpeakerQuery, Types.GetSpeakerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSpeakerQuery, Types.GetSpeakerQueryVariables>(GetSpeakerDocument, options);
      }
export function useGetSpeakerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSpeakerQuery, Types.GetSpeakerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSpeakerQuery, Types.GetSpeakerQueryVariables>(GetSpeakerDocument, options);
        }
export type GetSpeakerQueryHookResult = ReturnType<typeof useGetSpeakerQuery>;
export type GetSpeakerLazyQueryHookResult = ReturnType<typeof useGetSpeakerLazyQuery>;
export type GetSpeakerQueryResult = Apollo.QueryResult<Types.GetSpeakerQuery, Types.GetSpeakerQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: BigInt!) {
  user: getUserById(id: $id) {
    id
    code
    name
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserQuery, Types.GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserQuery, Types.GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<Types.GetUserQuery, Types.GetUserQueryVariables>;
export const GetUserByEmailDocument = gql`
    query getUserByEmail($email: String!) {
  user: getUserByEmail(email: $email) {
    id
    name
    lastName
    secondLastName
  }
}
    `;

/**
 * __useGetUserByEmailQuery__
 *
 * To run a query within a React component, call `useGetUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByEmailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByEmailQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGetUserByEmailQuery(baseOptions: Apollo.QueryHookOptions<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
      }
export function useGetUserByEmailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>(GetUserByEmailDocument, options);
        }
export type GetUserByEmailQueryHookResult = ReturnType<typeof useGetUserByEmailQuery>;
export type GetUserByEmailLazyQueryHookResult = ReturnType<typeof useGetUserByEmailLazyQuery>;
export type GetUserByEmailQueryResult = Apollo.QueryResult<Types.GetUserByEmailQuery, Types.GetUserByEmailQueryVariables>;
export const CreateViewDocument = gql`
    mutation createView($seconds: String!, $episodeId: ID!, $type: ViewType!) {
  createView(seconds: $seconds, episodeId: $episodeId, type: $type)
}
    `;
export type CreateViewMutationFn = Apollo.MutationFunction<Types.CreateViewMutation, Types.CreateViewMutationVariables>;

/**
 * __useCreateViewMutation__
 *
 * To run a mutation, you first call `useCreateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewMutation, { data, loading, error }] = useCreateViewMutation({
 *   variables: {
 *      seconds: // value for 'seconds'
 *      episodeId: // value for 'episodeId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useCreateViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateViewMutation, Types.CreateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateViewMutation, Types.CreateViewMutationVariables>(CreateViewDocument, options);
      }
export type CreateViewMutationHookResult = ReturnType<typeof useCreateViewMutation>;
export type CreateViewMutationResult = Apollo.MutationResult<Types.CreateViewMutation>;
export type CreateViewMutationOptions = Apollo.BaseMutationOptions<Types.CreateViewMutation, Types.CreateViewMutationVariables>;
export const UpdateViewDocument = gql`
    mutation updateView($seconds: String!, $episodeId: ID!) {
  updateView(seconds: $seconds, episodeId: $episodeId)
}
    `;
export type UpdateViewMutationFn = Apollo.MutationFunction<Types.UpdateViewMutation, Types.UpdateViewMutationVariables>;

/**
 * __useUpdateViewMutation__
 *
 * To run a mutation, you first call `useUpdateViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateViewMutation, { data, loading, error }] = useUpdateViewMutation({
 *   variables: {
 *      seconds: // value for 'seconds'
 *      episodeId: // value for 'episodeId'
 *   },
 * });
 */
export function useUpdateViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateViewMutation, Types.UpdateViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateViewMutation, Types.UpdateViewMutationVariables>(UpdateViewDocument, options);
      }
export type UpdateViewMutationHookResult = ReturnType<typeof useUpdateViewMutation>;
export type UpdateViewMutationResult = Apollo.MutationResult<Types.UpdateViewMutation>;
export type UpdateViewMutationOptions = Apollo.BaseMutationOptions<Types.UpdateViewMutation, Types.UpdateViewMutationVariables>;
export const CreateStoryViewDocument = gql`
    mutation createStoryView($storyId: ID!) {
  createStoryView(storyId: $storyId)
}
    `;
export type CreateStoryViewMutationFn = Apollo.MutationFunction<Types.CreateStoryViewMutation, Types.CreateStoryViewMutationVariables>;

/**
 * __useCreateStoryViewMutation__
 *
 * To run a mutation, you first call `useCreateStoryViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoryViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoryViewMutation, { data, loading, error }] = useCreateStoryViewMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useCreateStoryViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreateStoryViewMutation, Types.CreateStoryViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreateStoryViewMutation, Types.CreateStoryViewMutationVariables>(CreateStoryViewDocument, options);
      }
export type CreateStoryViewMutationHookResult = ReturnType<typeof useCreateStoryViewMutation>;
export type CreateStoryViewMutationResult = Apollo.MutationResult<Types.CreateStoryViewMutation>;
export type CreateStoryViewMutationOptions = Apollo.BaseMutationOptions<Types.CreateStoryViewMutation, Types.CreateStoryViewMutationVariables>;
export const UpdateStoryViewDocument = gql`
    mutation updateStoryView($seconds: Int!, $storyId: ID!) {
  updateStoryView(seconds: $seconds, storyId: $storyId)
}
    `;
export type UpdateStoryViewMutationFn = Apollo.MutationFunction<Types.UpdateStoryViewMutation, Types.UpdateStoryViewMutationVariables>;

/**
 * __useUpdateStoryViewMutation__
 *
 * To run a mutation, you first call `useUpdateStoryViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoryViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoryViewMutation, { data, loading, error }] = useUpdateStoryViewMutation({
 *   variables: {
 *      seconds: // value for 'seconds'
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useUpdateStoryViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdateStoryViewMutation, Types.UpdateStoryViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdateStoryViewMutation, Types.UpdateStoryViewMutationVariables>(UpdateStoryViewDocument, options);
      }
export type UpdateStoryViewMutationHookResult = ReturnType<typeof useUpdateStoryViewMutation>;
export type UpdateStoryViewMutationResult = Apollo.MutationResult<Types.UpdateStoryViewMutation>;
export type UpdateStoryViewMutationOptions = Apollo.BaseMutationOptions<Types.UpdateStoryViewMutation, Types.UpdateStoryViewMutationVariables>;
export const MarkStoryViewDropoutDocument = gql`
    mutation markStoryViewDropout($storyId: ID!) {
  markStoryViewDropout(storyId: $storyId)
}
    `;
export type MarkStoryViewDropoutMutationFn = Apollo.MutationFunction<Types.MarkStoryViewDropoutMutation, Types.MarkStoryViewDropoutMutationVariables>;

/**
 * __useMarkStoryViewDropoutMutation__
 *
 * To run a mutation, you first call `useMarkStoryViewDropoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryViewDropoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryViewDropoutMutation, { data, loading, error }] = useMarkStoryViewDropoutMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useMarkStoryViewDropoutMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkStoryViewDropoutMutation, Types.MarkStoryViewDropoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkStoryViewDropoutMutation, Types.MarkStoryViewDropoutMutationVariables>(MarkStoryViewDropoutDocument, options);
      }
export type MarkStoryViewDropoutMutationHookResult = ReturnType<typeof useMarkStoryViewDropoutMutation>;
export type MarkStoryViewDropoutMutationResult = Apollo.MutationResult<Types.MarkStoryViewDropoutMutation>;
export type MarkStoryViewDropoutMutationOptions = Apollo.BaseMutationOptions<Types.MarkStoryViewDropoutMutation, Types.MarkStoryViewDropoutMutationVariables>;
export const MarkStoryViewCompletionDocument = gql`
    mutation markStoryViewCompletion($storyId: ID!) {
  markStoryViewCompletion(storyId: $storyId)
}
    `;
export type MarkStoryViewCompletionMutationFn = Apollo.MutationFunction<Types.MarkStoryViewCompletionMutation, Types.MarkStoryViewCompletionMutationVariables>;

/**
 * __useMarkStoryViewCompletionMutation__
 *
 * To run a mutation, you first call `useMarkStoryViewCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkStoryViewCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markStoryViewCompletionMutation, { data, loading, error }] = useMarkStoryViewCompletionMutation({
 *   variables: {
 *      storyId: // value for 'storyId'
 *   },
 * });
 */
export function useMarkStoryViewCompletionMutation(baseOptions?: Apollo.MutationHookOptions<Types.MarkStoryViewCompletionMutation, Types.MarkStoryViewCompletionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.MarkStoryViewCompletionMutation, Types.MarkStoryViewCompletionMutationVariables>(MarkStoryViewCompletionDocument, options);
      }
export type MarkStoryViewCompletionMutationHookResult = ReturnType<typeof useMarkStoryViewCompletionMutation>;
export type MarkStoryViewCompletionMutationResult = Apollo.MutationResult<Types.MarkStoryViewCompletionMutation>;
export type MarkStoryViewCompletionMutationOptions = Apollo.BaseMutationOptions<Types.MarkStoryViewCompletionMutation, Types.MarkStoryViewCompletionMutationVariables>;
export const CreatePostViewDocument = gql`
    mutation createPostView($postId: ID!) {
  createPostView(postId: $postId)
}
    `;
export type CreatePostViewMutationFn = Apollo.MutationFunction<Types.CreatePostViewMutation, Types.CreatePostViewMutationVariables>;

/**
 * __useCreatePostViewMutation__
 *
 * To run a mutation, you first call `useCreatePostViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostViewMutation, { data, loading, error }] = useCreatePostViewMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useCreatePostViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.CreatePostViewMutation, Types.CreatePostViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.CreatePostViewMutation, Types.CreatePostViewMutationVariables>(CreatePostViewDocument, options);
      }
export type CreatePostViewMutationHookResult = ReturnType<typeof useCreatePostViewMutation>;
export type CreatePostViewMutationResult = Apollo.MutationResult<Types.CreatePostViewMutation>;
export type CreatePostViewMutationOptions = Apollo.BaseMutationOptions<Types.CreatePostViewMutation, Types.CreatePostViewMutationVariables>;
export const UpdatePostViewDocument = gql`
    mutation updatePostView($seconds: Int!, $postId: ID!) {
  updatePostView(seconds: $seconds, postId: $postId)
}
    `;
export type UpdatePostViewMutationFn = Apollo.MutationFunction<Types.UpdatePostViewMutation, Types.UpdatePostViewMutationVariables>;

/**
 * __useUpdatePostViewMutation__
 *
 * To run a mutation, you first call `useUpdatePostViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePostViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePostViewMutation, { data, loading, error }] = useUpdatePostViewMutation({
 *   variables: {
 *      seconds: // value for 'seconds'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useUpdatePostViewMutation(baseOptions?: Apollo.MutationHookOptions<Types.UpdatePostViewMutation, Types.UpdatePostViewMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.UpdatePostViewMutation, Types.UpdatePostViewMutationVariables>(UpdatePostViewDocument, options);
      }
export type UpdatePostViewMutationHookResult = ReturnType<typeof useUpdatePostViewMutation>;
export type UpdatePostViewMutationResult = Apollo.MutationResult<Types.UpdatePostViewMutation>;
export type UpdatePostViewMutationOptions = Apollo.BaseMutationOptions<Types.UpdatePostViewMutation, Types.UpdatePostViewMutationVariables>;
export const IncrementPostViewStoriesSeenDocument = gql`
    mutation incrementPostViewStoriesSeen($postId: ID!) {
  incrementPostViewStoriesSeen(postId: $postId)
}
    `;
export type IncrementPostViewStoriesSeenMutationFn = Apollo.MutationFunction<Types.IncrementPostViewStoriesSeenMutation, Types.IncrementPostViewStoriesSeenMutationVariables>;

/**
 * __useIncrementPostViewStoriesSeenMutation__
 *
 * To run a mutation, you first call `useIncrementPostViewStoriesSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIncrementPostViewStoriesSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [incrementPostViewStoriesSeenMutation, { data, loading, error }] = useIncrementPostViewStoriesSeenMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useIncrementPostViewStoriesSeenMutation(baseOptions?: Apollo.MutationHookOptions<Types.IncrementPostViewStoriesSeenMutation, Types.IncrementPostViewStoriesSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<Types.IncrementPostViewStoriesSeenMutation, Types.IncrementPostViewStoriesSeenMutationVariables>(IncrementPostViewStoriesSeenDocument, options);
      }
export type IncrementPostViewStoriesSeenMutationHookResult = ReturnType<typeof useIncrementPostViewStoriesSeenMutation>;
export type IncrementPostViewStoriesSeenMutationResult = Apollo.MutationResult<Types.IncrementPostViewStoriesSeenMutation>;
export type IncrementPostViewStoriesSeenMutationOptions = Apollo.BaseMutationOptions<Types.IncrementPostViewStoriesSeenMutation, Types.IncrementPostViewStoriesSeenMutationVariables>;
export const GetLastUserVisitDocument = gql`
    query getLastUserVisit {
  lastUserVisit: getLastUserVisit {
    tokenPassport
  }
}
    `;

/**
 * __useGetLastUserVisitQuery__
 *
 * To run a query within a React component, call `useGetLastUserVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastUserVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastUserVisitQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLastUserVisitQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetLastUserVisitQuery, Types.GetLastUserVisitQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetLastUserVisitQuery, Types.GetLastUserVisitQueryVariables>(GetLastUserVisitDocument, options);
      }
export function useGetLastUserVisitLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetLastUserVisitQuery, Types.GetLastUserVisitQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetLastUserVisitQuery, Types.GetLastUserVisitQueryVariables>(GetLastUserVisitDocument, options);
        }
export type GetLastUserVisitQueryHookResult = ReturnType<typeof useGetLastUserVisitQuery>;
export type GetLastUserVisitLazyQueryHookResult = ReturnType<typeof useGetLastUserVisitLazyQuery>;
export type GetLastUserVisitQueryResult = Apollo.QueryResult<Types.GetLastUserVisitQuery, Types.GetLastUserVisitQueryVariables>;
export const GetSeminarDocument = gql`
    query getSeminar($url: String!) {
  seminar: getSeminar(url: $url) {
    id
    name
    url
    diploma {
      id
      url
      posX
      posY
      fontSize
      isXCentered
    }
  }
}
    `;

/**
 * __useGetSeminarQuery__
 *
 * To run a query within a React component, call `useGetSeminarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeminarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeminarQuery({
 *   variables: {
 *      url: // value for 'url'
 *   },
 * });
 */
export function useGetSeminarQuery(baseOptions: Apollo.QueryHookOptions<Types.GetSeminarQuery, Types.GetSeminarQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetSeminarQuery, Types.GetSeminarQueryVariables>(GetSeminarDocument, options);
      }
export function useGetSeminarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetSeminarQuery, Types.GetSeminarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetSeminarQuery, Types.GetSeminarQueryVariables>(GetSeminarDocument, options);
        }
export type GetSeminarQueryHookResult = ReturnType<typeof useGetSeminarQuery>;
export type GetSeminarLazyQueryHookResult = ReturnType<typeof useGetSeminarLazyQuery>;
export type GetSeminarQueryResult = Apollo.QueryResult<Types.GetSeminarQuery, Types.GetSeminarQueryVariables>;
export const IsUserWithDownloadPerimissionsDocument = gql`
    query isUserWithDownloadPerimissions($email: String!, $url: String!) {
  isUserWithDownloadPerimissions(email: $email, url: $url)
}
    `;

/**
 * __useIsUserWithDownloadPerimissionsQuery__
 *
 * To run a query within a React component, call `useIsUserWithDownloadPerimissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserWithDownloadPerimissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserWithDownloadPerimissionsQuery({
 *   variables: {
 *      email: // value for 'email'
 *      url: // value for 'url'
 *   },
 * });
 */
export function useIsUserWithDownloadPerimissionsQuery(baseOptions: Apollo.QueryHookOptions<Types.IsUserWithDownloadPerimissionsQuery, Types.IsUserWithDownloadPerimissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.IsUserWithDownloadPerimissionsQuery, Types.IsUserWithDownloadPerimissionsQueryVariables>(IsUserWithDownloadPerimissionsDocument, options);
      }
export function useIsUserWithDownloadPerimissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.IsUserWithDownloadPerimissionsQuery, Types.IsUserWithDownloadPerimissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.IsUserWithDownloadPerimissionsQuery, Types.IsUserWithDownloadPerimissionsQueryVariables>(IsUserWithDownloadPerimissionsDocument, options);
        }
export type IsUserWithDownloadPerimissionsQueryHookResult = ReturnType<typeof useIsUserWithDownloadPerimissionsQuery>;
export type IsUserWithDownloadPerimissionsLazyQueryHookResult = ReturnType<typeof useIsUserWithDownloadPerimissionsLazyQuery>;
export type IsUserWithDownloadPerimissionsQueryResult = Apollo.QueryResult<Types.IsUserWithDownloadPerimissionsQuery, Types.IsUserWithDownloadPerimissionsQueryVariables>;