export const capitalize = ([firstLetter, ...restOfWord]: string[]): string =>
  (firstLetter ?? "").toUpperCase() + restOfWord.join("").toLocaleLowerCase()

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

export const capitalizeFirstWords = (word: any) =>
  word
    .split(" ")
    .map(
      ([firstLetter, ...restOfWord]: [
        firstLetter: string,
        restOfWord: string[]
      ]) =>
        (firstLetter ?? "").toUpperCase() +
        restOfWord.join("").toLocaleLowerCase()
    )
    .join(" ")
