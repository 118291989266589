import { Fragment, useEffect, useRef } from "react"
import { Dialog, Transition } from "@headlessui/react"

import { XMarkIcon } from "@heroicons/react/24/outline"

import useStories from "@src/hooks/use-stories"

import { useMutation } from "@apollo/client"

import useDetectSwipe from "@src/hooks/use-detect-swipe"

import Controls from "./controls"
import NavButtons from "./nav-buttons"
import ProgressBars from "./progress-bars"

import { useSelector, useDispatch } from "react-redux"

import { addEpisode,resetSelection } from "@src/app-store/episode-slice"

import MuxPlayer from "@mux/mux-player-react"

import UseHookWithRefCallback from "@src/hooks/use-hook-with-ref-callback"

import type { MuxPlayerRefAttributes } from "@mux/mux-player-react"

import Link from "next/link"

import useTracking from "@src/hooks/use-trackings"

import { MarkStoryViewDropoutDocument } from "@src/types/generated-graphql/apollo-client"

import {
  setOpenStoryModal,
  getOpenStoryModal,
  getStories,
  setStories,
  resetStoriesInteractions,
  resetPostInteractions,
} from "@src/app-store/story-slice"

const StoriesModal: React.FC = () => {
  const open = useSelector(getOpenStoryModal)
  const stories = useSelector(getStories)

  const isFirstTracking = useRef(true)

  const dispatch = useDispatch()

  const [callMarkDropout] = useMutation(MarkStoryViewDropoutDocument)

  const {
    indexes,
    setIndexes,
    animation,
    isTracking,
    post,
    onVideoEnd,
    setIsTracking,
    addEventListeners,
    removeEventListeners,
    setAnimation,
    setPost,
    setIsMuted,
    isMuted,
    toggleMute,
    playVideo,
    pauseVideo,
    goToPrevPost,
    goToNextPost,
    goToPrevStory,
    goToNextStory,
  } = useStories()

  const swipeHandlers = useDetectSwipe({
    onSwipedLeft: () => goToNextPost(indexes, setIndexes, stories),
    onSwipedRight: () => goToPrevPost(indexes, setIndexes),
  })

  const playerRef = UseHookWithRefCallback<
    null | MuxPlayerRefAttributes | undefined
  >(
    (node) => addEventListeners(node),
    (node) => removeEventListeners(node)
  )

  const { startTracking: startStoryTracking, stopTracking: stopStoryTracking } =
    useTracking({
      type: "story",
      story: stories?.get(indexes.postId!)?.[indexes.story],
    })

  const { startTracking: startPostTracking, stopTracking: stopPostTracking } =
    useTracking({
      type: "post",
      post,
    })

  useEffect(() => {
    const animations = document.getElementsByClassName("animations")
    for (const animation of animations) {
      animation.classList.remove("animate-progress-bar-2")
    }
  }, [indexes])

  useEffect(() => {
    if (open) {
      dispatch(addEpisode(null))
      dispatch(resetSelection())
      isFirstTracking.current = true
    } else {
      dispatch(resetStoriesInteractions())
      dispatch(resetPostInteractions())
      setIsTracking(false)
      dispatch(setStories(null))
      setIndexes({
        story: 0,
        group: 0,
        postId: null,
      })
      setAnimation(false)
      setPost(null)
      setIsMuted(true)
      isFirstTracking.current = true
    }
  }, [open])

  // 👇 for tracking and save it into BD
  useEffect(() => {
    if (isTracking) {
      startStoryTracking()
      startPostTracking()

      if (isFirstTracking.current) {
        toggleMute(false)
      }

      isFirstTracking.current = false
    } else {
      stopStoryTracking()
      stopPostTracking()
    }
  }, [isTracking])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => dispatch(setOpenStoryModal(false))}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/10 backdrop-blur-sm backdrop-saturate-[180%]">
            <div className="relative w-full h-full">
              {stories && indexes.postId ? (
                <>
                  <div className="absolute w-full h-auto inset-0 bg-black/60 backdrop-blur-lg backdrop-saturate-[180%]" />
                </>
              ) : null}
            </div>
          </div>
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex h-full justify-center items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full h-full my-8 rounded-lg ">
                <div
                  {...swipeHandlers}
                  className={`sm:max-w-md w-full h-full relative sm:mx-auto pb-3 ${
                    animation ? "animate-fade-in" : ""
                  }`}
                >
                  <NavButtons indexes={indexes} setIndexes={setIndexes} />

                  <div
                    className="absolute h-full w-1/3 z-10 top-0 "
                    onClick={() => goToPrevStory(indexes, setIndexes)}
                  />

                  <div
                    className="absolute h-full w-2/3 z-10 top-0 left-1/3 "
                    onClick={() => {
                      goToNextStory(indexes, setIndexes, stories)
                      callMarkDropout({
                        variables: {
                          storyId: stories?.get(indexes.postId!)?.[
                            indexes.story
                          ]!.id,
                        },
                      })
                    }}
                  />

                  <div className="absolute right-4 top-6 z-30">
                    <button
                      type="button"
                      className="text-white"
                      onClick={() => dispatch(setOpenStoryModal(false))}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {stories && indexes.postId ? (
                    <>
                      <div className="relative z-20 h-fit py-2 flex flex-col gap-4 px-4 bg-gradient-to-b from-black/20 to-black/0">
                        <ProgressBars
                          stories={stories}
                          indexes={indexes}
                          isTracking={isTracking}
                          post={post}
                        />

                        <Controls
                          isTracking={isTracking}
                          post={post}
                          setIsTracking={setIsTracking}
                          isMuted={isMuted}
                          toggleMute={toggleMute}
                          playVideo={playVideo}
                          pauseVideo={pauseVideo}
                        />
                      </div>

                      <MuxPlayer
                        ref={playerRef}
                        className="absolute inset-0 w-full h-full"
                        streamType="on-demand"
                        playbackId={
                          stories.get(indexes.postId!)![indexes.story]
                            ?.playbackId
                        }
                        autoPlay={true}
                        muted
                        onEnded={onVideoEnd}
                        onCanPlay={() => setIsTracking(true)}
                      />

                      {stories.get(indexes.postId)![indexes.story]
                        ?.storyLink ? (
                        <div className="z-10 absolute bottom-10 left-1/2 -translate-x-1/2">
                          {stories.get(indexes?.postId)![indexes.story]
                            ?.storyLink?.episode ? (
                            <Link
                              className="bg-white text-black px-4 py-1 rounded-xl"
                              onClick={() => dispatch(setOpenStoryModal(false))}
                              href={`/${
                                stories.get(indexes?.postId)![indexes.story]
                                  ?.storyLink?.episode?.podcast?.slug
                              }/evento/${
                                stories.get(indexes?.postId)![indexes.story]
                                  ?.storyLink?.episode?.share
                              }`}
                            >
                              Ver más
                            </Link>
                          ) : (
                            <a
                              className="bg-white text-black px-4 py-1 rounded-xl"
                              href={
                                stories.get(indexes?.postId)![indexes.story]
                                  ?.storyLink?.url!
                              }
                              target="_blank"
                              rel="noreferrer"
                            >
                              Ver más
                            </a>
                          )}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default StoriesModal
