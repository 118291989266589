import { useEffect } from "react"

import type { ReactPlayerProps } from "react-player"

import { getProgressPercentage } from "@src/utils/percentages-fns"

import { useDispatch } from "react-redux"

import {
  setProgress,
} from "@src/app-store/episode-slice"

const useAudioProgress = (
  // setProgress: Dispatch<SetStateAction<number>>,
  playerProps: ReactPlayerProps
) => {

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setProgress(getProgressPercentage(playerProps.duration, playerProps.played.playedSeconds)))  
  }, [playerProps])
}

export default useAudioProgress
