import { useCallback, useRef } from "react"

const UseHookWithRefCallback = <T>(
  onMount: (el: T) => void,
  onUnmount: (el: T) => void
) => {
  const nodeRef = useRef<T | null>(null)

  const setRef = useCallback(
    (node: T) => {
      if (nodeRef.current) {
        onUnmount(nodeRef.current)
      }

      nodeRef.current = node

      if (nodeRef.current) {
        onMount(nodeRef.current)
      }
    },
    [onMount, onUnmount]
  )

  return setRef
}

export default UseHookWithRefCallback
