import { GetLastUserVisitDocument } from "@src/types/generated-graphql/apollo-client"

import { initializeApolloClient } from "@src/api-clients/apollo-client"
const apolloClient = initializeApolloClient()

export function parseJwt(newToken: string) {
  const base64Url = newToken.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join("")
  )

  return JSON.parse(jsonPayload)
}

export const getEditProfileLink = async () => {
  const visit = await apolloClient.query({
    query: GetLastUserVisitDocument,
  })

  return (
    process.env.NEXT_PUBLIC_PASSPORT_EDIT +
    "?flow=p&access_token=" +
    visit.data.lastUserVisit.tokenPassport +
    "&url=" +
    process.env.NEXTAUTH_URL +
    "/update/profile"
  )
}

// https://account.lilly.com/es-MX/editprofile?flow=p&access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwdWJmMG9oaDRzSUNmaElUMjk3IiwiZW50ZXJwcmlzZUlkIjoiRU5USURfMTA0NjA2NzUxIiwicmVmZXJlbmNlSWQiOiJFTlRJRF8xOTIwMTI5MyIsImN1c3RvbWVySWQiOiJNWDAxNTIyMDgwSENQIiwiZmlyc3ROYW1lIjoiTcOpeGljbyIsImxhc3ROYW1lIjoiUHJ1ZWJhIiwic2Vjb25kYXJ5TGFzdE5hbWUiOiJEZXNhcnJvbGxvIiwiZW1haWwiOiJtZXhpY29AY29ycmVvLmNvbSIsInBob25lIjpudWxsLCJzcGVjaWFsdHkiOiJJbW11bm9sb2d5IiwiY291bnRyeUNvZGUiOiJNWCIsInByb2ZpbGVTdGF0dXMiOiJBY3RpdmUiLCJ1c2VyR3JvdXBzIjp7Imdyb3VwcyI6W3siZ3JvdXBJZCI6IjAwZ2Fra3ZwNWNmMWlyWm5SMjk3IiwiZ3JvdXBOYW1lIjoiTGlsbHkgUGFzc3BvcnQifV0sImdyb3VwQ291bnQiOjF9LCJpc3MiOiJodHRwczovL2FjY291bnQubGlsbHkuY29tL2FwaSIsImp0aSI6IjgzNWU4MGQzNGFhNDFjNTkzYWVhYTA4Mzg1OTg0ZGRlYjcyMCIsImlhdCI6MTY4NjYwNjM5OSwiZXhwIjoxNjg2NjA5OTk5fQ.A0QT1M2gSlXN9olqbyszBBGqbagKEmTG_Pce81oiR4e3MNPC5O3gY7vtHGUEPVNh81AW92hjtJqZWVYTZXqSJLKuxV0pBToyJjzrXaXUf5fveUPrGro_50mwWoj-kSz2sN5-i24sq3ANbApoRw6XDPP0l3nWgfB1VlT_PZkzEsTpMk92jfLjJXomBfdLrUexMEhidpW6U2AaHD7gZB0K_i7MD3zK7dG52_d1f3U6VwdiqpQAg3YOonk0aOU1ud8301zHdN2rviewiBGUYLv78EWboRQRMJyEXqeQhhfH1DAYZxqm4fjS0Bl5mfLS2_MeUis0WiqTa6O3BBUpxB-arw&url=https://dev.lilly-podcast.com/update/profile
// https://account.lilly.com/es-MX/editprofile?flow=p&access_token=eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAwdWJmMG9oaDRzSUNmaElUMjk3IiwiZW50ZXJwcmlzZUlkIjoiRU5USURfMTA0NjA2NzUxIiwicmVmZXJlbmNlSWQiOiJFTlRJRF8xOTIwMTI5MyIsImN1c3RvbWVySWQiOiJNWDAxNTIyMDgwSENQIiwiZmlyc3ROYW1lIjoiTcOpeGljbyIsImxhc3ROYW1lIjoiUHJ1ZWJhIiwic2Vjb25kYXJ5TGFzdE5hbWUiOiJEZXNhcnJvbGxvIiwiZW1haWwiOiJtZXhpY29AY29ycmVvLmNvbSIsInBob25lIjpudWxsLCJzcGVjaWFsdHkiOiJJbW11bm9sb2d5IiwiY291bnRyeUNvZGUiOiJNWCIsInByb2ZpbGVTdGF0dXMiOiJBY3RpdmUiLCJ1c2VyR3JvdXBzIjp7Imdyb3VwcyI6W3siZ3JvdXBJZCI6IjAwZ2Fra3ZwNWNmMWlyWm5SMjk3IiwiZ3JvdXBOYW1lIjoiTGlsbHkgUGFzc3BvcnQifV0sImdyb3VwQ291bnQiOjF9LCJpc3MiOiJodHRwczovL2FjY291bnQubGlsbHkuY29tL2FwaSIsImp0aSI6ImU5ZTZjNTYyOTk2YmZiYzMzMTIwNzRkZTBhNWMzNTlkYzJhZCIsImlhdCI6MTY4NjU5OTI2MSwiZXhwIjoxNjg2NjAyODYxfQ.ismhYf5eHLm1Gb5wg369DkI5ieCmeObFsijPRzfgSBWc7I1Sh2x7lHGcnW_BjO0njvS59MUtTobg09jdS6RjGUdG6ZysF1BNJSEnSq6poGwkKLcHgu_aOAofBX7uZxTh7Ojw2_cjiVv6TfBLyEXvJ3c_KJdLLUTePNw7lxKHcTRe_3mUzNlGYgqAnGgQIN-15z1vOOxln6mRJZ__HXFk3c9WKOSC5cm64NUvWfZvlbLDuUvKV5q7yhnaper8-GJo93S466d891fh_40432BgjdRulrNII5QEV0dnfg4eC9X0TEov2f6UL2hb9QZRK9tsxerX6aD5ctdu9LpdLM7tsA&url=https%3A%2F%2Flilly-podcast.com%2Fupdate%2Fprofile
