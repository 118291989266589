import type { PayloadAction } from "@reduxjs/toolkit"

import type { Episode } from "@src/types/generated-graphql/types"

import { createSlice } from "@reduxjs/toolkit"

import type { AppStoreType } from "./index"

import type { ReactPlayerProps } from "react-player"

// import type { SelectionType } from "@src/types"
import type { ViewType } from "@prisma/client"

export interface IEpisode {
  currentEpisode: Episode | null
  selection: ViewType
  playerProps: ReactPlayerProps
  progress: number
  percentage: number
  isFullscreen: boolean
}

const initialState: IEpisode = {
  currentEpisode: null,
  selection: "audio",
  playerProps: {
    pip: false,
    url: "",
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: false,
    played: {
      loaded: 0,
      loadedSeconds: 0,
      played: 0,
      playedSeconds: 0,
    },
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
  },
  progress: 0,
  percentage: 0,
  isFullscreen: false,
}

export const episodeSlice = createSlice({
  name: "ADMIN_CHAT",
  initialState,
  reducers: {
    addEpisode: (state, action: PayloadAction<Episode | null>) => {
      state.currentEpisode = action.payload
    },

    toggleSelection: (state) => {
      if (state.selection === "audio") {
        state.selection = "video"
      } else if (state.selection === "video") {
        state.selection = "audio"
      }
    },

    resetSelection: (state) => {
      state.selection = "audio"
    },

    setPlayerProps: (state, action: PayloadAction<ReactPlayerProps>) => {
      state.playerProps = action.payload
    },

    setProgress: (state, action: PayloadAction<number>) => {
      state.progress = action.payload
    },

    setPercentage: (state, action: PayloadAction<number>) => {
      state.percentage = action.payload
    },

    resetPercentage: (state) => {
      state.percentage = 0
    },

    resetProgress: (state) => {
      state.progress = 0
    },

    setIsFullscreen: (state, action: PayloadAction<boolean>) => {
      state.isFullscreen = action.payload
    },

    // resetIsFullscreen: (
    //   state,
    // ) => {
    //   state.isFullscreen = {isFull: false, shouldUpdate: true, fullPlayedSeconds: 0}
    // },
  },
})

export const {
  addEpisode,
  toggleSelection,
  resetSelection,
  setPlayerProps,
  setProgress,
  setPercentage,
  resetPercentage,
  resetProgress,
  setIsFullscreen,
  // resetIsFullscreen,
} = episodeSlice.actions

export const selectEpisode = (state: AppStoreType) =>
  state.episode.currentEpisode

export const getSelection = (state: AppStoreType) => state.episode.selection

export const getPlayerProps = (state: AppStoreType) => state.episode.playerProps

export const getProgress = (state: AppStoreType) => state.episode.progress

export const getPercentage = (state: AppStoreType) => state.episode.percentage

export const getIsFullscreen = (state: AppStoreType) =>
  state.episode.isFullscreen

export default episodeSlice.reducer
