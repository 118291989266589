type CProps = {
  message: string
}

const RedBadge: React.FC<CProps> = ({ message }) => {
  return (
    <span className="inline-flex items-center rounded-sm bg-red-600 px-2 py-1 text-xs font-medium text-red-100 uppercase tracking-wide	">
      {message}
    </span>
  )
}

export default RedBadge
