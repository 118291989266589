import type {
  Episode,
  Story,
  Maybe,
  Post,
} from "@src/types/generated-graphql/types"

import type { ViewType } from "@prisma/client"

import type ReactPlayer from "react-player"

import { useCallback, useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"

import {
  addStoriesInteractions,
  getStoriesInteractions,
  getPostInteractions,
  addPostInteractions,
} from "@src/app-store/story-slice"

import {
  CreateViewDocument,
  UpdateViewDocument,
  CreateStoryViewDocument,
  UpdateStoryViewDocument,
  CreatePostViewDocument,
  UpdatePostViewDocument,
} from "@src/types/generated-graphql/apollo-client"

import { getSelection } from "@src/app-store/episode-slice"

import { useMutation } from "@apollo/client"

type HProps =
  | {
      type: "episode"
      episode: Episode | null
      player: ReactPlayer | null
    }
  | {
      type: "story"
      story: Maybe<Story> | null
    }
  | {
      type: "post"
      post: Maybe<Post> | null
    }

const useTracking = (props: HProps) => {
  const selection = useSelector(getSelection)

  const [callViewCreator] = useMutation(CreateViewDocument)
  const [callViewUpdater] = useMutation(UpdateViewDocument)

  const [callStoryViewCreator] = useMutation(CreateStoryViewDocument)
  const [callStoryViewUpdater] = useMutation(UpdateStoryViewDocument)

  const [callPostViewCreator] = useMutation(CreatePostViewDocument)
  const [callPostViewUpdater] = useMutation(UpdatePostViewDocument)

  const [isTracking, setIsTracking] = useState(true)

  const trackingInterval = useRef<NodeJS.Timer>()
  const isFirstPlay = useRef(true)

  const startTracking = () => setIsTracking(true)
  const stopTracking = () => setIsTracking(false)

  const clearTrackingInterval = () => {
    console.log(`🔴 TRACKING STOPPED ${props.type}`)
    clearInterval(trackingInterval.current)
    trackingInterval.current = undefined
  }

  const dispatch = useDispatch()

  const storiesInteractions = useSelector(getStoriesInteractions)

  const postsInteractions = useSelector(getPostInteractions)

  const runCreateEpisodeTracking = useCallback(
    (player: ReactPlayer, episode: Episode, viewType: ViewType) => {

      if (isFirstPlay.current && props.type === "episode") {
        callViewCreator({
          variables: {
            seconds: String(player.getCurrentTime() ?? 0),
            episodeId: episode.id,
            type: viewType
          },
        })
      }

      isFirstPlay.current = false
    },
    [props.type === "episode" && props.episode]
  )

  const runUpdateEpisodeTracking = useCallback(
    (player: ReactPlayer, episode: Episode) => {
      if (props.type === "episode") {
        trackingInterval.current = setInterval(async () => {
          callViewUpdater({
            variables: {
              seconds: String(player.getCurrentTime()),
              episodeId: episode.id,
            },
          })

          console.log(`🟠 LIVE TRACKING UPDATED ${props.type}`)
        }, +process.env.NEXT_PUBLIC_MILLISECONDS!)
      }
    },
    [props.type === "episode" && props.episode]
  )

  const runCreateStoryTracking = useCallback(
    (story: Story) => {
      const currentStoryIsInIteraction = storiesInteractions.some(
        (storyInteraction) => storyInteraction.id === story.id
      )

      if (
        isFirstPlay.current &&
        !currentStoryIsInIteraction &&
        props.type === "story"
      ) {
        callStoryViewCreator({
          variables: {
            storyId: story.id,
          },
        })
      }

      dispatch(addStoriesInteractions(story))

      isFirstPlay.current = false
    },
    [props.type === "story" && props.story, storiesInteractions]
  )

  const runUpdateStoryTracking = useCallback(
    (story: Story) => {
      if (props.type === "story") {
        trackingInterval.current = setInterval(async () => {
          callStoryViewUpdater({
            variables: {
              seconds: 5000 / 1000,
              storyId: story.id,
            },
          })

          console.log(`🟠 LIVE TRACKING UPDATED ${props.type}`)
        }, +5000)
      }
    },
    [props.type === "story" && props.story]
  )

  const runCreatePostTracking = useCallback(
    (post: Post) => {
      const currentPostIsInIteraction = postsInteractions.some(
        (postInteraction) => postInteraction.id === post.id
      )

      if (
        isFirstPlay.current &&
        !currentPostIsInIteraction &&
        props.type === "post"
      ) {
        callPostViewCreator({
          variables: {
            postId: post.id,
          },
        })
      }

      dispatch(addPostInteractions(post))

      isFirstPlay.current = false
    },
    [props.type === "story" && props.story, storiesInteractions]
  )

  const runUpdatePostTracking = useCallback(
    (post: Post) => {
      if (props.type === "post") {
        trackingInterval.current = setInterval(async () => {
          callPostViewUpdater({
            variables: {
              seconds: 5000 / 1000,
              postId: post.id,
            },
          })

          console.log(`🟠 LIVE TRACKING UPDATED ${props.type}`)
        }, +5000)
      }
    },
    [props.type === "post" && props.post]
  )

  // 👇  episode
  useEffect(() => {
    if (props.type === "episode") {
      isFirstPlay.current = true

      return () => {
        clearTrackingInterval()
      }
    }
  }, [props.type === "episode", selection])

  // 👇  episode
  useEffect(() => {
    if (props.type === "episode" && props.episode) {
      if (!props.player) {
        return
      }

      if (isTracking) {
        console.log(`🟢 TRACKING STARTED ${selection} ${props.type}`)
        runCreateEpisodeTracking(props.player, props.episode, selection)
        runUpdateEpisodeTracking(props.player, props.episode)
      } else {
        clearTrackingInterval()
      }
    }
  }, [
    props.type === "episode" && props.episode && props.player,
    isTracking,
    selection,
  ])

  // 👇  episode
  useEffect(() => {
    if (props.type === "episode" && props.episode) {
      console.log("new episode: ", props.episode)

      isFirstPlay.current = true
      // setIsTracking(false)

      return () => {
        clearTrackingInterval()
      }
    }
  }, [props.type === "episode" && props.episode])

  // 👇  episode
  // useEffect(() => {
  //   if (props.type === "episode") {
  //     console.log("🚀 ~ useTracking ~ selection:", selection)
  //   }
  // }, [props.type === "episode" && selection])

  // 👇  story
  useEffect(() => {
    if (props.type === "story" && props.story) {
      if (isTracking) {
        console.log(`🟢 TRACKING STARTED ${props.type}`)
        runCreateStoryTracking(props.story)
        runUpdateStoryTracking(props.story)
      } else {
        clearTrackingInterval()
      }
    }
  }, [isTracking, props.type === "story" && props.story])

  // 👇  story
  useEffect(() => {
    if (props.type === "story" && props.story) {
      isFirstPlay.current = true
      setIsTracking(false)

      return () => {
        clearTrackingInterval()
      }
    }
  }, [props.type === "story" && props.story])

  // 👇 post
  useEffect(() => {
    if (props.type === "post" && props.post) {
      isFirstPlay.current = true
      setIsTracking(false)

      return () => {
        clearTrackingInterval()
      }
    }
  }, [props.type === "post" && props.post])

  // 👇 post
  useEffect(() => {
    if (props.type === "post" && props.post) {
      if (isTracking) {
        console.log(`🟢 TRACKING STARTED ${props.type}`)
        runCreatePostTracking(props.post)
        runUpdatePostTracking(props.post)
      } else {
        clearTrackingInterval()
      }
    }
  }, [isTracking, props.type === "post" && props.post])

  return { isTracking, startTracking, stopTracking }
}

export default useTracking
