import { useEffect } from "react"

// import type { ReactPlayerProps } from "react-player"

import type { Episode } from "@src/types/generated-graphql/types"

import { getSelection } from "@src/app-store/episode-slice"

import { useDispatch, useSelector } from "react-redux"

import { setPlayerProps, getPlayerProps } from "@src/app-store/episode-slice"

const useAudioUrlChange = (currentSelectedEpisode: Episode | null) => {
  const selection = useSelector(getSelection)

  const dispatch = useDispatch()

  const playerProps = useSelector(getPlayerProps)

  useEffect(() => {
    if (!currentSelectedEpisode) return

    dispatch(
      setPlayerProps({
        ...playerProps,
        playing: true,
        url: process.env.NEXT_PUBLIC_STREAM_MUX?.replace(
          "XXX",
          selection === "video" && currentSelectedEpisode.playbackId
            ? currentSelectedEpisode.playbackId!
            : currentSelectedEpisode?.externalId!
        ),
      })
    )
  }, [currentSelectedEpisode, selection])
}

export default useAudioUrlChange
