import { useRef, useEffect, useState } from "react"

import ReactPlayer from "react-player"

import type { OnProgressProps } from "react-player/base"

import useAudioControls from "@src/hooks/use-audio-controls"
import useAudioProgress from "@src/hooks/use-audio-progress"
import useTracking from "@src/hooks/use-trackings"
import useAudioUrlChange from "@src/hooks/use-audio-url-change"

import Image from "next/image"

import ToggleVideo from "@components/episodes/toggle-video"

import { IoIosArrowDropdown } from "react-icons/io"

import {
  BsSkipEndFill,
  BsSkipStartFill,
  BsPause,
  BsPlayFill,
} from "react-icons/bs"

import PlaylistIcon from "../public/listaPlayer.png"

import { getFormattedVideoDuration } from "@src/utils/date-fns"

import Link from "next/link"

import { GetPrevNextEpisodeDocument } from "@src/types/generated-graphql/apollo-client"

import { useLazyQuery } from "@apollo/client"

import { useDispatch } from "react-redux"

import { XMarkIcon } from "@heroicons/react/24/outline"

import { TbWindowMaximize } from "react-icons/tb"

import useScreenSize from "@src/hooks/use-screen-size"

import {
  addEpisode,
  selectEpisode,
  getSelection,
  resetSelection,
  resetPercentage,
  getPlayerProps,
  getProgress,
  setProgress,
  setPercentage,
  getIsFullscreen,
  // resetIsFullscreen,
} from "@src/app-store/episode-slice"

import { useSelector } from "react-redux"

import { useRouter } from "next/router"
import type { Episode } from "@src/types/generated-graphql/types"

const AudioPlayer = () => {
  const [isBigPlayer, setIsBigPlayer] = useState(true)
  const screenSize = useScreenSize();


  const { asPath } = useRouter()

  const playerRef = useRef(null)

  const dispatch = useDispatch()

  const currentSelectedEpisode = useSelector(selectEpisode)

  const playerProps = useSelector(getPlayerProps)

  const selection = useSelector(getSelection)

  const progress = useSelector(getProgress)

  const isFullscreen = useSelector(getIsFullscreen)

  const [getNextPrevEpisodes, { data }] = useLazyQuery<{
    prevEpisode: Episode | null
    nextEpisode: Episode | null
  }>(GetPrevNextEpisodeDocument)

  const { handlePlay, handleProgress, handleDuration } = useAudioControls(
    playerRef.current!,
    playerProps
  )

  useAudioProgress(playerProps)

  useAudioUrlChange(currentSelectedEpisode)

  const { startTracking, stopTracking } = useTracking({
    type: "episode",
    episode: currentSelectedEpisode,
    player: playerRef.current,
  })

  const {
    url,
    playing,
    controls,
    light,
    volume,
    muted,
    loop,
    playbackRate,
    pip,
  } = playerProps

  useEffect(() => {
    setIsBigPlayer(true)
  }, [screenSize])

  useEffect(() => {
    console.log("✅ CURRENT SELECTED EPISODE:", currentSelectedEpisode)

    if (!currentSelectedEpisode) return

    getNextPrevEpisodes({
      variables: { episodeId: currentSelectedEpisode?.id },
    })

    dispatch(resetPercentage())

    if (playerProps.url) {
      // dispatch(setPlayerProps({ ...playerProps, playing: true }))
      startTracking()
    }
  }, [currentSelectedEpisode])

  useEffect(() => {
    if (playerProps.playing) {
      startTracking()
    } else {
      stopTracking()
    }
  }, [playerProps.playing])

  return (
    <>
      {currentSelectedEpisode ? (
        <div className="fixed z-30 bottom-0 w-[98%] left-1/2 -translate-x-1/2 lg:w-[76%] lg:max-w-7xl lg:left-auto lg:translate-x-0">
          <div className="relative w-full bg-[#181818] rounded-xl flex flex-col px-4 my-6 md:h-28 md:items-center md:flex-row md:space-x-4 md:justify-between">
            <button
              onClick={() => {
                dispatch(addEpisode(null))
                dispatch(resetSelection())
                dispatch(resetPercentage())
              }}
              className="absolute right-3 top-2"
            >
              <XMarkIcon
                className="h-6 w-6 text-lilly-gray-100"
                aria-hidden="true"
              />
            </button>

            <div className="!ml-0 h-full flex flex-col sm:flex-row sm:space-x-3 lg:items-center xl:mb-0">
              <div
                className={`relative transition-all ease-in-out ${
                  selection === "video" && currentSelectedEpisode.playbackId
                    ? asPath ==
                      `/${currentSelectedEpisode?.podcast?.slug}/evento/${currentSelectedEpisode?.share}`
                      ? isBigPlayer
                        ? "big-player"
                        : "mini-player"
                      : "mini-player"
                    : "hidden"
                }`}
              >
                {asPath ==
                `/${currentSelectedEpisode?.podcast?.slug}/evento/${currentSelectedEpisode?.share}` ? (
                  <button
                    onClick={() => setIsBigPlayer(!isBigPlayer)}
                    className={`hidden xl:flex items-center justify-start p-2 z-10 absolute ${
                      isBigPlayer ? "top-2 left-4" : "right-6"
                    }`}
                  >
                    {isBigPlayer ? (
                      <IoIosArrowDropdown className="text-white w-8 h-8" />
                    ) : (
                      <TbWindowMaximize className="w-8 h-8 shadow-2xl" />
                    )}
                  </button>
                ) : null}

                <div className={`w-full h-full ${ isBigPlayer ? "xl:w-9/12 mx-auto" : "" }`}>
                  <ReactPlayer
                    className="w-80 h-28"
                    playsinline={true}
                    ref={playerRef}
                    width="100%"
                    height="100%"
                    url={url}
                    pip={pip}
                    playing={playing}
                    controls={controls}
                    light={light}
                    loop={loop}
                    playbackRate={playbackRate}
                    volume={isFullscreen ? 0 : volume}
                    muted={muted}
                    onProgress={(state: OnProgressProps) => {
                      handleProgress(state, playerProps)
                    }}
                    onDuration={(duration: number) =>
                      handleDuration(duration, playerProps)
                    }
                  />
                </div>

                <div
                  className={`${
                    asPath ==
                    "/" +
                      currentSelectedEpisode?.podcast?.slug +
                      "/evento/" +
                      currentSelectedEpisode?.share
                      ? "hidden"
                      : ""
                  } absolute top-2 right-2 shadow-2xl p-0.5 bg-[hsl(0,0%,0%)]/30 rounded-md`}
                >
                  <Link
                    className="shadow-2xl"
                    href={
                      "/" +
                      currentSelectedEpisode.podcast?.slug +
                      "/evento/" +
                      currentSelectedEpisode.share
                    }
                  >
                    <TbWindowMaximize className="w-8 h-8 shadow-2xl" />
                  </Link>
                </div>
              </div>

              {currentSelectedEpisode &&
              currentSelectedEpisode.podcast?.thumbnailUrl &&
              selection === "audio" &&
              currentSelectedEpisode.playbackId ? (
                <div className="w-20 h-20 aspect-square">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_IMAGES_URL! +
                      "/" +
                      currentSelectedEpisode.podcast?.thumbnailUrl
                    }
                    className="w-full h-full rounded-lg mb-2 xl:mb-0"
                    alt=""
                    height={200}
                    width={200}
                  />
                </div>
              ) : null}

              <div className="max-w-[20rem] py-2">
                <Link
                  href={
                    "/" +
                    currentSelectedEpisode.podcast?.slug +
                    "/evento/" +
                    currentSelectedEpisode.share
                  }
                >
                  <h2
                    style={{
                      color:
                        currentSelectedEpisode.podcast?.color?.primary ??
                        "#fff",
                    }}
                    className="mb-2 text-sm !leading-4 md:text-base"
                  >
                    {currentSelectedEpisode.name}
                  </h2>
                </Link>
                <p className="mb-2 text-2xs md:text-xs">
                  {currentSelectedEpisode.speakers?.length
                    ? currentSelectedEpisode.speakers.map((speaker, i) => (
                        <span
                          key={i}
                          dangerouslySetInnerHTML={{
                            __html:
                              speaker?.name +
                              (currentSelectedEpisode &&
                              currentSelectedEpisode.speakers!.length &&
                              currentSelectedEpisode.speakers!.length - 1 <= i
                                ? ""
                                : ",&nbsp;"),
                          }}
                        />
                      ))
                    : null}
                </p>
              </div>
            </div>

            <div className="flex items-center justify-center space-x-4 mb-4 xl:mb-0">
              <div className="!text-xs">
                <ToggleVideo
                  episode={currentSelectedEpisode as unknown as any}
                />
              </div>

              {asPath.includes(
                `/episodios/${currentSelectedEpisode.podcast?.slug}`
              ) ? null : (
                <div className="relative w-8 h-8">
                  <Link
                    href={`/episodios/${currentSelectedEpisode.podcast?.slug}`}
                  >
                    <Image
                      src={PlaylistIcon}
                      width={100}
                      height={100}
                      alt="Lilly Podcast Icon"
                      className="text-lilly-gray-100 w-8 h-8"
                    />
                  </Link>
                </div>
              )}

              <button
                onClick={() => {
                  dispatch(addEpisode(data?.prevEpisode!))
                  dispatch(resetSelection())
                  dispatch(resetPercentage())
                }}
                className={`${!data || !data.prevEpisode ? "opacity-30" : ""}`}
                disabled={!data || !data.prevEpisode}
              >
                <BsSkipStartFill className="w-10 h-10 text-lilly-gray-100" />
              </button>

              <div className="bg-transparent border-2 border-lilly-gray rounded-full flex justify-center items-center">
                <button onClick={() => handlePlay(playerProps)}>
                  {" "}
                  {playerProps.playing ? (
                    <BsPause className="w-12 h-12 text-lilly-gray-100" />
                  ) : (
                    <BsPlayFill className="w-12 h-12 text-lilly-gray-100" />
                  )}
                </button>
              </div>

              <button
                onClick={() => {
                  dispatch(addEpisode(data?.nextEpisode!))
                  dispatch(resetSelection())
                  dispatch(resetPercentage())
                }}
                className={`${data && data.nextEpisode ? "" : "opacity-30"}`}
                disabled={!data || !data.nextEpisode}
              >
                <BsSkipEndFill className="w-10 h-10 text-lilly-gray-100" />
              </button>
            </div>

            <div className="w-full xl:w-80 xl:mt-8">
              <div className="progress-played relative">
                <input
                  type="range"
                  min="0"
                  max="100"
                  value={progress}
                  step="5"
                  style={{
                    color: currentSelectedEpisode.podcast?.color?.primary
                      ? currentSelectedEpisode.podcast.color.primary
                      : "#fff",
                  }}
                  onChange={(e) => {
                    console.log(
                      "🚀 ~ AudioPlayer ~ +e.target.value:",
                      +e.target.value
                    )

                    dispatch(setProgress(+e.target.value))
                    dispatch(setPercentage(+e.target.value))
                    // setProgressControl(+e.target.value, playerRef.current!, playerProps)
                  }}
                />
              </div>

              <div className="flex justify-between text-lilly-gray-200">
                <span>
                  {getFormattedVideoDuration(playerProps.played.playedSeconds)}
                </span>
                <span>
                  {getFormattedVideoDuration(playerProps.duration ?? 0)}
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default AudioPlayer
