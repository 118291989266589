import { FillPrevArrow, FillNextArrow } from "@components/arrows"

import type { Post } from "@src/types/generated-graphql/types"

import type { GroupStoriesType } from "@src/types"

import type { Settings } from "react-slick"

export const getSettings = (options: Partial<Settings> = {}) => {
  const defaultOptions: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    nextArrow: <FillNextArrow />,
    prevArrow: <FillPrevArrow />,
    responsive: [
      {
        breakpoint: 1215,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 966,
        settings: {
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 690,
        settings: {
          slidesToShow: 2,
        },
      },

      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    ...options,
  }

  return defaultOptions
}

export const handleSlide = (direction: "left" | "right") => {
  const slider = document.getElementsByClassName("carousel-body")[0]
  if (direction === "left") slider.scrollBy(-400, 0)
  else slider.scrollBy(400, 0)
}

export const groupStories = (posts: Post[]) => {
  const stories: GroupStoriesType = new Map()

  for (const post of posts) {
    if (post.stories && post.stories.length) {
      stories.set(post.id, post.stories)
    }
  }
  return stories
}

export const getStoryKey = (key: number, stories: GroupStoriesType) =>
  Array.from(stories.keys())[key]

export const filterPreviousPosts = (
  allPosts: Post[],
  selectedPostId: string
): Post[] => {
  const selectedPostIndex = allPosts.findIndex(
    (post) => post.id === selectedPostId
  )

  if (selectedPostIndex === -1) {
    return []
  }

  return allPosts.slice(selectedPostIndex)
}

export const getPostById = (allPosts: Post[], selectedPostId: string): Post => {
  return allPosts.filter((post) => post.id === selectedPostId)[0]
}
