import "../styles/globals.css"
import "../styles/index.scss"

import { useEffect } from "react"

import type { AppProps } from "next/app"

import type { CustomAppProps } from "@src/types"

import { ApolloProvider } from "@apollo/client"
// import { client } from '@src/apollo-client';
import { useApolloClient } from "@src/api-clients/apollo-client"

import { SessionProvider } from "next-auth/react"

import { Provider } from "react-redux"
import { appStore } from "@src/app-store"

import Layout from "@components/layout"

import Script from "next/script"

import AOS from "aos"
import "aos/dist/aos.css"

import * as fbq from "@src/utils/fpixel"

function MyApp({
  Component,
  pageProps: { session, initialApolloState, ...pageProps },
}: AppProps<CustomAppProps>) {
  const apolloClient = useApolloClient(initialApolloState)

  useEffect(() => {
    AOS.init()
  }, [])

  return (
    <SessionProvider session={session}>
      <ApolloProvider client={apolloClient}>
        <Provider store={appStore}>
          <Layout>
            <Script
              strategy="beforeInteractive"
              dangerouslySetInnerHTML={{
                __html: `
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
        )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
            
              ttq.load('CNNL4O3C77U5T6M9PENG');
              ttq.page();
            }(window, document, 'ttq');
            `,
              }}
            />

            <Script
              id="fb-pixel"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', ${fbq.FB_PIXEL_ID});
          `,
              }}
            />

            {/* google */}
            <Script
              async
              src="https://www.googletagmanager.com/gtag/js?id=AW-11296878339"
            />

            <Script
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
              
                gtag('config', 'AW-11296878339');
              
                `,
              }}
            />

            {/* LINKEDIN */}
            <Script
              dangerouslySetInnerHTML={{
                __html: `
                  _linkedin_partner_id = "5276346";
                  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                  window._linkedin_data_partner_ids.push(_linkedin_partner_id);
							`,
              }}
            />

            <Script
              dangerouslySetInnerHTML={{
                __html: `
                (function(l) {
                  if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
                  window.lintrk.q=[]}
                  var s = document.getElementsByTagName("script")[0];
                  var b = document.createElement("script");
                  b.type = "text/javascript";b.async = true;
                  b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                  s.parentNode.insertBefore(b, s);})(window.lintrk);
                  
							`,
              }}
            />

            <Component {...pageProps} />
          </Layout>
        </Provider>
      </ApolloProvider>
    </SessionProvider>
  )
}

export default MyApp
