import { useCallback, useEffect } from "react"

import type { OnProgressProps } from "react-player/base"

import { useDispatch, useSelector } from "react-redux"

import {
  setPlayerProps,
  getPercentage,
  getSelection,
} from "@src/app-store/episode-slice"

import type ReactPlayer from "react-player"

import { getProgressSeconds } from "@src/utils/percentages-fns"

import type { ReactPlayerProps } from "react-player"

const useAudioControls = (
  player: ReactPlayer,
  playerProps: ReactPlayerProps
) => {
  const dispatch = useDispatch()

  const currentPercentage = useSelector(getPercentage)

  const selection = useSelector(getSelection)

  useEffect(() => {
    if (player) {
      setProgressControl(currentPercentage, player, playerProps)
    }
  }, [currentPercentage, player])

  // useEffect(() => {
  //   if (player) {
  //     console.log("🚀 ~ selection:", selection, prevSelection.current)

  //     if (prevSelection.current && selection !== prevSelection.current) {
  //       setProgressControl(progress, player, playerProps)
  //     }
  //   }
  // }, [selection, playerProps.duration])

  useEffect(() => {
    if (player) {
      setTimeout(() => {
        player.seekTo(playerProps.played.playedSeconds)
      }, 300)
    }
  }, [selection])

  const handlePlay = useCallback((playerProps: ReactPlayerProps) => {
    dispatch(setPlayerProps({ ...playerProps, playing: !playerProps.playing }))
  }, [])

  const handlePlay2 = useCallback(
    (playerProps: ReactPlayerProps, isPlaying: boolean) => {
      dispatch(setPlayerProps({ ...playerProps, playing: isPlaying }))
    },
    []
  )

  const handleProgress = useCallback(
    (state: OnProgressProps, playerProps: ReactPlayerProps) => {
      dispatch(setPlayerProps({ ...playerProps, played: state }))
    },
    []
  )

  const handleDuration = useCallback(
    (duration: number, playerProps: ReactPlayerProps) => {
      dispatch(setPlayerProps({ ...playerProps, duration: duration }))
    },
    []
  )

  const setProgressControl = useCallback(
    (
      percentage: number,
      player: ReactPlayer,
      playerProps: ReactPlayerProps
    ) => {
      player.seekTo(
        parseFloat(String(getProgressSeconds(playerProps.duration, percentage)))
      )
    },
    []
  )

  const unstable_UpdateProgress = useCallback(() => {
    // setPlayerState((prevState) => ({
    //   ...prevState,
    //   played: {
    //     playedSeconds: 456.096667,
    //     played: 0.5014417060131514,
    //     loadedSeconds: 555.029333,
    //     loaded: 0.6102102377934314,
    //   },
    // }))
  }, [])

  return {
    handlePlay,
    handlePlay2,
    handleProgress,
    handleDuration,
    setProgressControl,
    unstable_UpdateProgress,
  }
}

export default useAudioControls
