import { Dispatch, Fragment, SetStateAction, useEffect, useState } from "react"

import { signOut } from "next-auth/react"

import { Menu, Transition } from "@headlessui/react"

import Link from "next/link"

import { classNames } from "@src/utils/words"

import { useSession } from "next-auth/react"

import { getEditProfileLink } from "@src/utils/login-fns"

type CProps = {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

const TopMenu: React.FC<CProps> = ({ setSidebarOpen }) => {
  const { data: session } = useSession()

  const [editProfileUrl, setEditProfileUrl] = useState<string | null>(null)

  const getUrl = async () => {
    setEditProfileUrl(await getEditProfileLink())
  }

  useEffect(() => {
    getUrl()
  }, [getUrl])

  return (
    <div className="flex flex-shrink-0 items-center justify-between px-4 relative">
      <Link href="/" onClick={() => setSidebarOpen(false)}>
        <img
          className="h-8 w-auto "
          src={`${process.env.NEXT_PUBLIC_IMAGES_URL}/Lilly_Podcast_rojo.png`}
          alt=""
        />
      </Link>

      <Menu as="div" className="inline-block px-3 text-left py-4">
        <div>
          <Menu.Button className="group w-12 flex items-center justify-center">
            <span className="flex items-center justify-between">
              <ul className="space-y-1">
                {Array.from(Array(3), (_e, i) => (
                  <li key={i} className="bg-gray-200 h-1 w-1 rounded-full" />
                ))}
              </ul>
            </span>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute translate-x-2/3-x w-60 right-0 left-0 z-10 mx-3 mt-1 origin-top divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {() => (
                  <p className="text-gray-700 block px-4 py-2 text-sm">
                    Hola {session?.user?.name}
                  </p>
                )}
              </Menu.Item>
            </div>
            <div className="py-1">
              {editProfileUrl ? (
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href={editProfileUrl}
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left"
                      )}
                    >
                      Editar perfil
                    </a>
                  )}
                </Menu.Item>
              ) : null}
            </div>
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() =>
                      signOut({ callbackUrl: process.env.NEXTAUTH_URL })
                    }
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 w-full text-left"
                        : "text-gray-700",
                      "block px-4 py-2 text-sm w-full text-left"
                    )}
                  >
                    Salir
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}

export default TopMenu
