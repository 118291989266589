import type { Dispatch, SetStateAction } from "react"

import type { Area } from "@src/types/generated-graphql/types"

import Image from "next/image"

import VideoPillsLogo from "../../public/videopills-logo.png"

import RedBadge from "@components/red-badge"

const navigation = [
  { name: "Mi Lista", href: "/milista" },
  { name: "Ver todo el contenido", href: "/contenido/todo" },
]

import Link from "next/link"

import { useRouter } from "next/router"

type CProps = {
  areas: Area[]
  totalPosts: number
  setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

const SidebarMenu: React.FC<CProps> = ({
  areas,
  setSidebarOpen,
  totalPosts,
}) => {
  const { query, asPath } = useRouter()

  return (
    <nav className="flex-1 space-y-1 px-2 pb-4">
      <div className="mb-4">
        {totalPosts ? (
          <Link
            href={"/videopills"}
            onClick={() => setSidebarOpen(false)}
            className={`group mb-4 flex items-center rounded-md gap-2 px-2 py-2 bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C] text-red-lilly font-semibold ${
              asPath === "/videopills"
                ? "border border-[#606060] rounded-lg bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C] font-semibold"
                : "text-white"
            }`}
          >
            <Image src={VideoPillsLogo} className="w-5 h-auto" alt="" />
            <span className="text-red-lilly">Videopills</span>
            <RedBadge message="Nuevo" />
          </Link>
        ) : null}

        <div className="bg-[#1D1D1D] px-2 py-2  rounded-md w-full">
          <h3 className=" text-white inline font-semibold mb-4 ">
            Áreas terapéuticas
          </h3>
        </div>
      </div>
      <ul className="!mb-4">
        {areas.length &&
          areas.map((item, i) => (
            <li key={i}>
              <Link
                href={`/areaterapeutica/${item.slug}`}
                onClick={() => {
                  setSidebarOpen(false)

                    // @ts-ignore
                  ttq.track("ViewContent", {
                    contents: [
                      {
                        content_id: item.id,
                        content_type: "área terapeútica",
                        value: item.name,
                      },
                    ],
                  })
                }}
                className={`group flex items-center rounded-md px-2 py-2 text-sm font-extralight hover:bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C] hover:text-red-lilly hover:font-semibold mb-1 ${
                  item.slug === query?.area_slug
                    ? "border border-[#606060] rounded-lg bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C] text-red-lilly font-semibold "
                    : "text-white"
                }`}
              >
                {item.name}
              </Link>
            </li>
          ))}
      </ul>

      <ul className="!mb-4">
        {navigation.map((item) => (
          <li key={item.name}>
            <Link
              href={item.href}
              onClick={() => setSidebarOpen(false)}
              className={`group flex items-center rounded-md px-2 py-2 text-lg font-medium text-white hover:bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C]  hover:text-red-lilly hover:font-semibold ${
                asPath === item.href
                  ? "border border-[#606060] rounded-lg bg-gradient-to-r from-[#1F1F1F] to-[#1C1C1C]"
                  : ""
              }`}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>

      <div className=" text-white font-medium text-lg px-2 pb-2 hover:text-red-lilly">
        <a
          href="https://lilly-conexiones.com/informacion-para-prescribir"
          target="_blank"
          rel="noreferrer"
        >
          Información para prescribir
        </a>
      </div>
    </nav>
  )
}

export default SidebarMenu
