import type { InMemoryCacheConfig } from '@apollo/client';
import type { IncomingMessage } from 'http';

import { createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { signOut } from 'next-auth/react';

import { LOGIN_PAGE_PATH } from '@src/pages-permissions/rules';

export const inMemoryCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    // Query: {
    //   fields: {
    //     getUserMessages: {
    //       keyArgs: ['eventSessionId'],
    //       merge: (existing: PaginatedMessages, incoming: PaginatedMessages): PaginatedMessages => {
    //         if (existing) {
    //           return { pageInfo: incoming.pageInfo, data: [...existing.data, ...incoming.data] };
    //         }

    //         return incoming;
    //       },
    //     },
    //     getDashboardNewMessages: {
    //       keyArgs: ['eventSessionId'],
    //       merge: (existing: PaginatedMessages, incoming: PaginatedMessages): PaginatedMessages => {
    //         if (existing) {
    //           return { pageInfo: incoming.pageInfo, data: [...existing.data, ...incoming.data] };
    //         }

    //         return incoming;
    //       },
    //     },
    //     getDashboardReadMessages: {
    //       keyArgs: ['eventSessionId'],
    //       merge: (existing: PaginatedMessages, incoming: PaginatedMessages): PaginatedMessages => {
    //         if (existing) {
    //           return { pageInfo: incoming.pageInfo, data: [...existing.data, ...incoming.data] };
    //         }

    //         return incoming;
    //       },
    //     },
    //   },
    // },
  },
};

export const initHttpLink = (req?: IncomingMessage) =>
  createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    credentials: 'include',
    // @ts-ignore
    headers: { cookie: req?.headers.cookie },
  });

export const httpLinkError = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    for (const error of graphQLErrors) {
      if (error.message === 'Unauthenticated' && typeof window !== 'undefined') {
        signOut({ callbackUrl: LOGIN_PAGE_PATH });
      }
    }
  }
});
