import type { GroupStoriesType, IndexType } from "@src/types"

import type { Post } from "@src/types/generated-graphql/types"

type CProps = {
  stories: GroupStoriesType
  indexes: IndexType
  isTracking: boolean
  post: Post | null
}

const ProgressBars: React.FC<CProps> = ({
  stories,
  indexes,
  isTracking,
  post,
}) => {
  return (
    <div className="w-full flex gap-2 h-1 relative z-10">
      {stories.get(indexes.postId!)!.map((story, i) => (
        <div
          key={story?.id}
          className="bg-progress-bar-50/50 flex-1 h-full rounded-md"
        >
          <div
            style={{
              animationDuration:
                (i < indexes.story
                  ? "0.7"
                  : stories.get(indexes.postId!)![indexes.story]?.duration!) +
                "s",
            }}
            className={`bg-white w-0 h-full rounded-md animations ${
              i === indexes.story && indexes.postId === post?.id
                ? isTracking
                  ? "animate-progress-bar"
                  : "animate-progress-bar animation-paused"
                : ""
            }
                              
                              ${i < indexes.story ? "w-full" : ""}

                              ${
                                i === indexes.story - 1
                                  ? "animate-progress-bar-2"
                                  : ""
                              }
                              `}
          />
        </div>
      ))}
    </div>
  )
}

export default ProgressBars
