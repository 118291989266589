import {
  BsFillPlayFill,
  BsFillPauseFill,
  BsFillVolumeMuteFill,
  BsFillVolumeUpFill,
} from "react-icons/bs"

import Image from "next/image"

import type { Dispatch, SetStateAction } from "react"

import type { Post } from "@src/types/generated-graphql/types"

type CProps = {
  isTracking: boolean
  post: Post | null
  setIsTracking: Dispatch<SetStateAction<boolean>>
  isMuted: boolean
  toggleMute: () => void
  playVideo: () => void
  pauseVideo: () => void
}

const Controls: React.FC<CProps> = ({
  isTracking,
  post,
  setIsTracking,
  isMuted,
  toggleMute,
  playVideo,
  pauseVideo,
}) => {
  return (
    <>
      {post ? (
        <div className="relative w-full min-h-[2rem] z-10 flex justify-between items-end gap-6">
          <div className="flex gap-2 flex-1 flex-col">
            <div className="flex items-center">
              <Image
                src={
                  process.env.NEXT_PUBLIC_IMAGES_URL! + "/" + post.area?.iconUrl
                }
                alt=""
                className="w-5 h-auto"
                width={100}
                height={100}
              />
              <p
                className={
                  "text-[" + (post.area?.color?.primary ?? "#FFF") + "]"
                }
                style={
                  post.area?.color
                    ? {
                        background:
                          "linear-gradient(90deg," +
                          post.area.color.gradient +
                          "," +
                          post.area.color.primary +
                          ") text",
                        // backgroundClip: "border-box",
                        // WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      }
                    : {}
                }
              >
                {post.area?.name ?? ""}
              </p>
            </div>

            <div className="flex gap-2 items-center">
              <Image
                src={
                  process.env.NEXT_PUBLIC_IMAGES_URL +
                  "/" +
                  post.secondaryImage +
                  "?w=200&h=200"
                }
                alt="Post image"
                className="rounded-full w-10 h-10"
                width={100}
                height={100}
              />

              <div>
                <p className="drop-shadow-xl shadow-black">{post.title}</p>
              </div>
            </div>
          </div>

          <div className="controls w-20 flex">
            {isTracking ? (
              <button
                onClick={() => {
                  pauseVideo()
                }}
              >
                <BsFillPauseFill className="icon-stories-controls" />
              </button>
            ) : (
              <button
                onClick={() => {
                  playVideo()
                  setIsTracking(true)
                }}
              >
                <BsFillPlayFill className="icon-stories-controls" />
              </button>
            )}

            <button
              onClick={() => {
                toggleMute()
              }}
            >
              {isMuted ? (
                <BsFillVolumeMuteFill className="icon-stories-controls" />
              ) : (
                <BsFillVolumeUpFill className="icon-stories-controls" />
              )}
            </button>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default Controls
