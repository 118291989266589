import type { PageAccessRule } from "@src/types"

import { pathToRegexp } from "path-to-regexp"

export const LOGIN_PAGE_PATH = "/"
export const HOME_PAGE_PATH = "/home"
export const ANY_ROLE_IDENTIFIER = "*"

/**
 * READ "path-to-regexp" DOCUMENTATION FOR PATH MATCHER SYNTAX CREATION
 * https://github.com/pillarjs/path-to-regexp
 */
export const PAGES_ACCESS_RULES: PageAccessRule[] = [
  {
    pageName: "/",
    pathRegexp: pathToRegexp("/"),
    isPublic: true,
  },
  {
    pageName: "home",
    pathRegexp: pathToRegexp("/home"),
    isPublic: false,
  },

  {
    pageName: "areaterapeutica",
    pathRegexp: pathToRegexp("/areaterapeutica/:area_slug"),
    isPublic: false,
  },

  {
    pageName: "Episode",
    pathRegexp: pathToRegexp("/:podcast/evento/:episode_share"),
    isPublic: false,
  },

  {
    pageName: "Episodes",
    pathRegexp: pathToRegexp("/episodios/:episode_slug"),
    isPublic: false,
  },

  {
    pageName: "MyList",
    pathRegexp: pathToRegexp("/milista"),
    isPublic: false,
  },

  {
    pageName: "All Content",
    pathRegexp: pathToRegexp("/contenido/todo"),
    isPublic: false,
  },

  {
    pageName: "Login Passport",
    pathRegexp: pathToRegexp("/login/passport"),
    isPublic: true,
  },

  {
    pageName: "Access to reports",
    pathRegexp: pathToRegexp("/reportesAdmin"),
    isPublic: true,
  },

  {
    pageName: "Reports",
    pathRegexp: pathToRegexp("/reportes"),
    isPublic: false,
    isReportPage: true,
  },

  {
    pageName: "Reports episode ondemand",
    pathRegexp: pathToRegexp("/reporte/eventos/ondemand"),
    isPublic: false,
    isReportPage: true,
  },

  {
    pageName: "Reports podcast ondemand",
    pathRegexp: pathToRegexp("/reporte/seminarios/ondemand"),
    isPublic: false,
    isReportPage: true,
  },

  {
    pageName: "Reports consolidado",
    pathRegexp: pathToRegexp("/reporte/consolidado"),
    isPublic: false,
    isReportPage: true,
  },

  {
    pageName: "Reports ondemand",
    pathRegexp: pathToRegexp("/reporte/usuarios/registrados"),
    isPublic: false,
    isReportPage: true,
  },

  {
    pageName: "Recap 2023",
    pathRegexp: pathToRegexp("/recap-2023"),
    isPublic: false,
  },
]
