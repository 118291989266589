import { configureStore } from "@reduxjs/toolkit"

import episodeSlice from "./episode-slice"
import counterSlice from "./counter-slice"
import storySlice from "./story-slice"

export const appStore = configureStore({
  reducer: {
    episode: episodeSlice,
    counter: counterSlice,
    story: storySlice,
  },
})

export type AppStoreType = ReturnType<typeof appStore.getState>
export type AppDispatch = typeof appStore.dispatch
